import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export interface ToggleGroupOption<T extends string = string> {
  displayText: string;
  value: T;
}

@Component({
  selector: 'app-toggle-group',
  template: `
    <syn-radio-group
      [value]="value"
      size="medium"
      required="true"
      (syn-change)="onValueChange($event)"
      [disabled]="disabled"
      class="container"
      #radioGroup
    >
      @for (option of options; track option.value) {
        <syn-radio [value]="option.value">{{ option.displayText }}</syn-radio>
      }
    </syn-radio-group>
  `,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToggleGroupComponent<T extends string = string> {
  @Input() options: ToggleGroupOption<T>[] = [];
  @Input() value!: T;
  @Input() disabled: boolean = false;
  @Output() select = new EventEmitter<T>();

  @ViewChild('radioGroup') child!: ElementRef<HTMLInputElement>;

  onValueChange(event: Event) {
    if (event.target === this.child.nativeElement) {
      const value: T = this.child.nativeElement.value as T;
      this.select.emit(value);
    }
  }
}
