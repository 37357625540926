import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ErrorType } from '../global-error-handler';
import { AbstractErrorListener } from './error-listener';
import { StateService } from '../../store/state';

export const HttpStatusCodes = {
  BadRequest: 400,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
};

export type HttpErrorCallback = (error: HttpErrorResponse) => boolean;

let callbacks: HttpErrorCallback[] = [];

export function registerHttpErrorCallback(listener: HttpErrorCallback) {
  callbacks.push(listener);
}

export function unregisterHttpErrorCallback(callback: HttpErrorCallback) {
  callbacks = callbacks.filter((entry) => entry != callback);
}

@Injectable({ providedIn: 'root' })
export class HttpErrorListener extends AbstractErrorListener {
  private state = inject(StateService);

  override handleError(error: ErrorType) {
    if (!isHttpError(error)) {
      return super.handleError(error);
    } else {
      for (const callback of callbacks) {
        // If a callback handled the error, we can exit
        if (callback(error)) {
          return;
        }
      }
      this.state.error.set({ type: 'ApiError', message: this.mapServerSideErrors(error) });
    }
  }

  private mapServerSideErrors(error: HttpErrorResponse): string {
    switch (error.status) {
      case HttpStatusCodes.BadRequest:
        return this.mapBadRequestErrorMessages(error.error);
      case HttpStatusCodes.NotFound:
        return $localize`:@@error.http.notFound:The server could not find the resource you were looking for.`;
      case HttpStatusCodes.Conflict:
        return $localize`:@@error.http.conflict:The server has encountered a conflict. Perhaps an object with the same name already exists?`;
      case HttpStatusCodes.Forbidden:
        return $localize`:@@error.http.forbidden:You are not allowed to access this resource.`;
      default:
        return $localize`:@@error.http.unknown:Oops, something went wrong. We had problems communicating with the server.`;
    }
  }

  private mapBadRequestErrorMessages(error?: { name?: string }): string {
    if (error?.name === 'InvalidParameters') {
      return $localize`:@@error.http.invalidParameters:The server could not handle the last request since it contained invalid parameters.`;
    }

    if (error?.name === 'MaximumLengthExceeded') {
      return $localize`:@@error.http.maximumLengthExceeded:The server could not process the last request because one or more inputs exceeded the manageable length.`;
    }

    return $localize`:@@error.http.unspecifiedBadRequest:The server could not handle the last request due to invalid syntax.`;
  }
}

function isHttpError(error: ErrorType): error is HttpErrorResponse {
  return hasName(error) && error.name === 'HttpErrorResponse';
}

function hasName(value: unknown): value is { name: unknown } {
  return typeof value === 'object' && value !== null && 'name' in value;
}
