import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteGroupEvent, PageChangeEvent, GroupListComponent } from '../group-list/group-list.component';
import { GroupListService } from '../group-list/group-list.service';
import { AsyncPipe } from '@angular/common';
import { SearchFieldComponent } from '../../shared/components/search-field/search-field.component';
import { ButtonComponent } from '../../shared/components/button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-managed-groups',
  template: `@if (viewModel$ | async; as viewModel) {
    <div class="margin-1">
      <div class="margin-block-1 flex-row col-gap-1">
        <app-search-field
          [formControl]="searchText"
          i18n-placeholder="@@searchFieldPlaceholder"
          placeholder="Search Groups"
          data-testid="search-managed-groups"
          style="width: 100%;"
        ></app-search-field>

        <app-button
          class="create-button"
          data-testid="create-group-managed"
          icon="plus"
          routerLink="/new"
          i18n="@@groupListTabMenu.createGroupButton"
        >
          Create New Group
        </app-button>
      </div>
      <app-group-list
        [groups]="viewModel.groups"
        [rowCount]="viewModel.pageLength"
        [totalCount]="viewModel.totalCounts.manager"
        [loading]="!viewModel.pageLoaded"
        [showDeleteButton]="true"
        [baseLink]="'managed-groups'"
        (onPageChange)="onPageChange($event)"
        (onDelete)="onDelete($event)"
      ></app-group-list>
    </div>
  }`,
  styles: [],
  standalone: true,
  imports: [
    SearchFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    GroupListComponent,
    ButtonComponent,
    RouterLink,
    AsyncPipe,
  ],
})
export class ManagedGroupsComponent implements OnInit, OnDestroy {
  private groupListService = inject(GroupListService);
  readonly viewModel$ = this.groupListService.viewModel$;
  readonly searchText = new FormControl('');

  @ViewChild(GroupListComponent) groupList!: GroupListComponent;

  ngOnInit(): void {
    this.groupListService.subscribe(this.searchText.valueChanges);
  }

  ngOnDestroy(): void {
    this.groupListService.unsubscribe();
  }

  public open(): void {
    this.groupListService.open('manager');
  }

  onPageChange(event: PageChangeEvent) {
    this.groupListService.onPageChange(event);
  }

  onDelete(event: DeleteGroupEvent) {
    this.groupListService.onDelete(event);
  }
}
