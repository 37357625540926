import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, first, mergeMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { getAccessToken } from './auth';
import { StateService } from '../shared/store/state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private state = inject(StateService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const merged = combineLatest([getAccessToken()]);
    return merged.pipe(
      first(),
      mergeMap(([token]) => {
        if (req.url.startsWith(environment.apiUrl)) {
          const support = this.state.supportView.active();
          const params = support ? req.params.append('support', true) : req.params;
          const headers = req.headers.append('Authorization', 'Bearer ' + token);
          req = req.clone({ params, headers });
        }
        return next.handle(req);
      })
    );
  }
}
