import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `<footer>
    <div class="footer-parent">
      <div class="footer-content">
        <div class="float-left flex-row col-gap-1">
          <a href="https://www.sick.com/imprint/w/imprint/" i18n="@@footer.imprint">Imprint</a>
          |
          <a href="https://www.sick.com/de/en/w/tac" i18n="@@footer.termsAndConditions">Terms and Conditions</a>
          |
          <a href="https://www.sick.com/de/en/w/terms-of-use" i18n="@@footer.termsOfUse">Terms of Use</a>
          |
          <a href="https://www.sick.com/de/en/w/dataprotection" i18n="@@footer.privacyPolicy">Privacy Policy</a>
        </div>
        <div class="float-right">
          <span>© {{ year }} SICK AG</span>
        </div>
      </div>
    </div>
  </footer>`,
  styles: [
    `
      footer {
        width: 100%;
        background-color: #2d3234;
        margin: 0;
        padding: 0;
        border: 0;
      }

      .footer-parent {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        text-align: center;
        padding: 24px;
        margin: 0 15%;
      }

      .footer-content {
        width: 100%;
        color: #ffffff;
      }

      a {
        color: #ffffff;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    `,
  ],
})
export class FooterComponent {
  year = new Date().getFullYear();
}
