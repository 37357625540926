import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TableDirective } from './table.directive';
import { NgTemplateOutlet } from '@angular/common';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  imports: [TableModule, NgTemplateOutlet],
})
export class TableComponent<T> implements AfterContentInit {
  @Input() values: T[] = [];
  @Input() rowsPerPage = 0;
  @Input() totalValuesCount = 0;
  @Input() loading = false;
  @Input() showPagination = true;
  @Input() scrollHeight = '';
  @Input() hoverable = false;

  @Output('onLazyLoad') lazyLoadEmitter = new EventEmitter();

  @ContentChildren(TableDirective) private tableTemplates?: QueryList<TableDirective>;

  headerTemplate: TemplateRef<unknown> | null = null;
  bodyTemplate: TemplateRef<unknown> | null = null;
  footerTemplate: TemplateRef<unknown> | null = null;

  @Input() first = 0;

  get selectionMode(): 'single' | null {
    return this.hoverable ? 'single' : null;
  }

  ngAfterContentInit() {
    this.tableTemplates?.forEach((template) => {
      switch (template.type) {
        case 'header':
          this.headerTemplate = template.templateRef;
          break;

        case 'body':
          this.bodyTemplate = template.templateRef;
          break;

        case 'footer':
          this.footerTemplate = template.templateRef;
          break;
      }
    });
  }
}
