import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-inline-notification',
  template: ` <syn-alert
    [@.disabled]="!animated"
    @transitionTrigger
    [attr.variant]="variant"
    [attr.open]="active ? true : null"
    [closeable]="closeable"
    (remove)="onClose.emit()"
  >
    <syn-icon slot="icon" [attr.name]="getIcon()"></syn-icon>
    <ng-content></ng-content>
  </syn-alert>`,
  styles: [],
  animations: [
    trigger('transitionTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)', transformOrigin: '50% 0' }),
        animate('400ms ease-in-out', style({ opacity: 1, transform: 'scaleY(1)' })),
      ]),
      transition(':leave', [animate('400ms', style({ opacity: 0 }))]),
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InlineNotificationComponent {
  @Input() active = true;
  @Input() variant: 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'primary';
  @Input() closeable = true;
  @Input() animated = true;
  @Output() onClose = new EventEmitter<void>();

  getIcon() {
    switch (this.variant) {
      case 'primary':
        return 'info';
      case 'success':
        return 'check';
      case 'neutral':
        return 'info';
      case 'warning':
        return ' warning';
      case 'danger':
        return 'error';
      default:
        return 'info';
    }
  }
}
