import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ErrorBannerComponent } from './shared/components/error-banner/error-banner.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer.component';
import { BannerComponent } from './shared/components/banner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [HeaderComponent, ErrorBannerComponent, BannerComponent, RouterOutlet, FooterComponent],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {}
}
