import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-back-link',
  template: `<div class="back-link" [routerLink]="'..'" data-testid="back">
    ❮
    <span i18n="@@back">Back</span>
  </div> `,
  styles: [
    `
      .back-link {
        width: fit-content;
        color: var(--syn-color-primary-600);
        cursor: pointer;
      }

      .back-link:hover {
        text-decoration: underline;
      }
    `,
  ],
  imports: [RouterLink],
})
export class BackLinkComponent {}
