import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { mergeMap, of, tap } from 'rxjs';
import { AuthUser } from 'src/app/auth/types';
import { GroupUser } from '../model';
import {
  groupCreationActions,
  groupListActions,
  invitationActions,
  selectLists,
  selectOpenList,
} from './groups.feature';
import { groupApiActions } from '../../api/actions';
import { GroupApi } from '../../api/group';
import { StateService } from '../../shared/store/state';

export function authUserToGroupUser(authUser: AuthUser | undefined, fallbackUserName: string): GroupUser {
  return {
    id: authUser?.id ?? '',
    name: authUser?.fullName ?? fallbackUserName,
    email: authUser?.email,
    isVisible: authUser ? true : false,
  };
}

@Injectable()
export class GroupsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private state = inject(StateService);
  private api = inject(GroupApi);
  private router = inject(Router);

  loadGroupsOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupListActions.initialized, groupApiActions.groupDeleted),
      concatLatestFrom(() => [this.store.select(selectLists)]),
      mergeMap(([_, lists]) => {
        const search =
          lists['member'].searchQuery !== '' ||
          lists['manager'].searchQuery !== '' ||
          lists['visible'].searchQuery !== '';
        return this.api.loadGroupsOverview(
          {
            managerSearch: lists['manager'].searchQuery,
            managerOffset: lists['manager'].offset,
            managerMax: lists['manager'].pageLength,
            memberSearch: lists['member'].searchQuery,
            memberOffset: lists['member'].offset,
            memberMax: lists['member'].pageLength,
            visibleSearch: lists['visible'].searchQuery,
            visibleOffset: lists['visible'].offset,
            visibleMax: lists['visible'].pageLength,
          },
          search
        );
      })
    )
  );

  loadGroupsAfterSearchChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(groupListActions.searchQueryChanged),
      concatLatestFrom(() => [this.store.select(selectLists), this.store.select(selectOpenList)]),
      mergeMap(([action, lists, openList]) => {
        const userData = this.state.authUser();
        return this.api.loadGroups(userData, action.query, 0, lists[openList].pageLength, openList, true);
      })
    )
  );

  loadGroupsAfterTabOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupListActions.opened),
      concatLatestFrom(() => [this.store.select(selectLists), this.store.select(selectOpenList)]),
      mergeMap(([{ first }, lists, openList]) => {
        const list = lists[openList];
        if (first || list.pages?.[list.offset]) {
          return of(groupListActions.pageAlreadyLoaded());
        }

        const userData = this.state.authUser();
        return this.api.loadGroups(userData, list.searchQuery, 0, list.pageLength, openList, true);
      })
    )
  );

  loadGroupsAfterOffsetChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(groupListActions.offsetChanged),
      concatLatestFrom(() => [this.store.select(selectLists), this.store.select(selectOpenList)]),
      mergeMap(([action, lists, openList]) => {
        const list = lists[openList];
        const pageExists = list.pages?.[action.offset] !== undefined;
        if (pageExists) {
          return of(groupListActions.pageAlreadyLoaded());
        } else {
          const userData = this.state.authUser();
          return this.api.loadGroups(userData, list.searchQuery, action.offset, list.pageLength, openList, false);
        }
      })
    )
  );

  createGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupCreationActions.groupCreationRequested),
      mergeMap(({ event }) => this.api.createGroup(event.properties, event.target))
    )
  );

  navigateAfterCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(groupApiActions.groupCreated),
        tap(({ id, target }) => {
          if (target === 'group-details') {
            return this.router.navigate(['managed-groups', id]);
          } else {
            return this.router.navigate(['managed-groups']);
          }
        })
      ),
    { dispatch: false }
  );

  navigateAfterUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(groupApiActions.groupUpdated),
        tap(({ group }) => {
          return this.router.navigate(['managed-groups', group.id]);
        })
      ),
    { dispatch: false }
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupListActions.deletionRequested),
      mergeMap(({ groupId }) => this.api.deleteGroup(groupId))
    )
  );

  getInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.requested),
      mergeMap(({ params }) => this.api.getInvitation(params))
    )
  );

  respondToInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.responseChosen),
      mergeMap(({ params, response }) => this.api.respondToInvitation(params, response))
    )
  );

  navigateAfterInvitationResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(groupApiActions.invitationResponseUpdated),
        tap(({ result }) => {
          if (result.response === 'accepted') {
            // Navigate to the groups page if the invitation was accepted
            return this.router.navigate(['my-groups', result.group_id]);
          } else if (result.response === 'declined') {
            // If declined, just go back to the home page
            return this.router.navigate(['/']);
          } else {
            console.error('Unexpected value for invitation response', result);
            throw new Error('Unexpected value for invitation response: ' + result.types);
          }
        })
      ),
    { dispatch: false }
  );
}
