import {
  AfterContentInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ManagedGroupsComponent } from '../managed-groups/managed-groups.component';
import { MyGroupsComponent } from '../my-groups/my-groups.component';
import { VisibleGroupsComponent } from '../visible-groups/visible-groups.component';
import { createSelector, Store } from '@ngrx/store';
import { groupListActions, selectLists } from '../store/groups.feature';
import { TotalCounts } from '../group-list/group-list.service';
import { PillComponent } from '../../shared/components/pill.component';
import { AsyncPipe } from '@angular/common';

const selectViewModel = createSelector(selectLists, (lists): TotalCounts => {
  return {
    manager: lists['manager'].totalCount,
    member: lists['member'].totalCount,
    visible: lists['visible'].totalCount,
  };
});

@Component({
  selector: 'app-groups-overview',
  template: `
    <syn-tab-group (syn-tab-show)="onTabShow($event)" [attr.activeTab]="showActiveTab()" #tabGroup>
      <syn-tab-panel name="managed-groups" data-testid="managed-groups-tab" [attr.active]="isActive('managed-groups')">
        <app-managed-groups></app-managed-groups>
      </syn-tab-panel>
      <syn-tab-panel name="my-groups" data-testid="my-groups-tab" [attr.active]="isActive('my-groups')">
        <app-my-groups></app-my-groups>
      </syn-tab-panel>
      <syn-tab-panel name="visible-groups" data-testid="visible-groups-tab" [attr.active]="isActive('visible-groups')">
        <app-visible-groups></app-visible-groups>
      </syn-tab-panel>
      <syn-tab slot="nav" panel="managed-groups" [attr.active]="isActive('managed-groups')">
        <span i18n="@@groupListTabMenu.manageableGroups">Management</span>
        @if (viewModel$ | async; as viewModel) {
          <app-pill class="tab-menu-pill">{{ viewModel.manager }}</app-pill>
        }
      </syn-tab>
      <syn-tab slot="nav" panel="my-groups" [attr.active]="isActive('my-groups')">
        <span i18n="@@groupListTabMenu.myGroups">Membership</span>
        @if (viewModel$ | async; as viewModel) {
          <app-pill class="tab-menu-pill">{{ viewModel.member }}</app-pill>
        }
      </syn-tab>
      <syn-tab slot="nav" panel="visible-groups" [attr.active]="isActive('visible-groups')">
        <span i18n="@@groupListTabMenu.visibleGroups">All Visible</span>
        @if (viewModel$ | async; as viewModel) {
          <app-pill class="tab-menu-pill">{{ viewModel.visible }}</app-pill>
        }
      </syn-tab>
    </syn-tab-group>
  `,
  styles: [
    `
      syn-tab-panel {
        --padding: 0;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ManagedGroupsComponent, MyGroupsComponent, VisibleGroupsComponent, PillComponent, AsyncPipe],
})
export class GroupsOverviewComponent implements AfterContentInit, OnInit {
  store = inject(Store);
  viewModel$ = this.store.select(selectViewModel);
  loading = true;

  @ViewChild('tabGroup') tabGroup?: ElementRef;
  @ViewChild(ManagedGroupsComponent) managedGroups!: ManagedGroupsComponent;
  @ViewChild(MyGroupsComponent) myGroups!: MyGroupsComponent;
  @ViewChild(VisibleGroupsComponent) visibleGroups!: VisibleGroupsComponent;

  ngOnInit(): void {
    this.store.dispatch(groupListActions.initialized());
    this.showActiveTab();
  }

  ngAfterContentInit(): void {}

  onTabShow(event: Event) {
    if (event.target === this.tabGroup?.nativeElement) {
      const cevent = event as CustomEvent<{ name: string }>;
      switch (cevent.detail.name) {
        case 'managed-groups':
          this.managedGroups.open();
          window.history.pushState({}, '', 'managed-groups');
          break;
        case 'my-groups':
          this.myGroups.open();
          window.history.pushState({}, '', 'my-groups');
          break;
        case 'visible-groups':
          this.visibleGroups.open();
          window.history.pushState({}, '', 'visible-groups');
          break;
      }
    }
  }

  showActiveTab(): string {
    const active = this.getActiveTab();
    if (active !== '') {
      this.tabGroup?.nativeElement.show(active);
    }
    return active;
  }

  getActiveTab(): string {
    const pathParts = window.location.pathname.split('/');
    if (pathParts.length > 0) {
      const lastPart = pathParts[pathParts.length - 1];
      switch (lastPart) {
        case 'managed-groups':
        case 'my-groups':
        case 'visible-groups':
          return lastPart;
      }
    }
    console.log('None active');
    return '';
  }

  isActive(tab: string): true | null {
    return this.getActiveTab() === tab ? true : null;
  }
}
