<div class="full-page">
  <app-header></app-header>
  <app-error-banner></app-error-banner>
  <app-banner></app-banner>

  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
