import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpErrorCallback,
  HttpStatusCodes,
  registerHttpErrorCallback,
  unregisterHttpErrorCallback,
} from '../../../shared/error-handling/error-listener/http-error-listener';
import { GroupPropertiesSubmitEvent, GroupVisibility, NavigationTarget } from '../../model';
import { ButtonComponent } from '../../../shared/components/button.component';
import { ToggleSwitchComponent } from '../../../shared/components/toggle-switch.component';
import { TextAreaComponent } from '../../../shared/components/text-area/text-area.component';
import { TextFieldComponent } from '../../../shared/components/text-field/text-field.component';
import { RouterLink } from '@angular/router';

export function getVisibilityInfoText(hasCompanyVisibility: boolean): string {
  if (hasCompanyVisibility) {
    return $localize`:@@groupDetails.details.visibility-company-info:Anyone in your company and anyone authorised to view and manage will be able to view this group.`;
  } else {
    return $localize`:@@groupDetails.details.visibility-private-info:This group is only visible to its members, managers, and anyone with permission to view it. Other users in your company CANNOT view this group.`;
  }
}

@Component({
  selector: 'app-group-properties-form',
  templateUrl: './group-properties-form.component.html',
  styleUrls: ['./group-properties-form.component.css'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextFieldComponent,
    TextAreaComponent,
    ToggleSwitchComponent,
    ButtonComponent,
    RouterLink,
  ],
})
export class GroupPropertiesFormComponent implements OnInit, OnDestroy {
  @Input() submitButton?: string;
  @Input() cancelButton = false;
  @Input() customerName?: string;
  @Input() name = '';
  @Input() description = '';
  @Input() visibility: GroupVisibility = 'private';
  @Input() id = '';
  @Input() loading = false;

  @Output() submit = new EventEmitter<GroupPropertiesSubmitEvent>();

  MIN_NAME_LENGTH = 3;
  MAX_NAME_LENGTH = 200;
  MAX_DESCRIPTION_LENGTH = 2000;

  form = new FormGroup({
    name: new FormControl(
      '',
      Validators.compose([
        // minLength does not imply required, i.e. a string of length 0 passes minLength(3)!
        Validators.required,
        Validators.minLength(this.MIN_NAME_LENGTH),
        Validators.maxLength(this.MAX_NAME_LENGTH),
      ])
    ),
    hasCompanyVisibility: new FormControl(false),
    description: new FormControl('', Validators.maxLength(this.MAX_DESCRIPTION_LENGTH)),
  });

  private location = inject(Location);

  nameNotUniqueError = false;
  errorCallback: HttpErrorCallback = (error: HttpErrorResponse): boolean => {
    if (error.status === HttpStatusCodes.Conflict) {
      this.nameNotUniqueError = true;
      return true;
    }
    return false;
  };

  ngOnInit(): void {
    this.form.setValue({
      name: this.name,
      hasCompanyVisibility: this.visibility == 'company',
      description: this.description,
    });

    registerHttpErrorCallback(this.errorCallback);
  }

  ngOnDestroy(): void {
    unregisterHttpErrorCallback(this.errorCallback);
  }

  getNameErrorText() {
    const nameField = this.form.get('name');
    if (nameField?.errors?.['required'] || nameField?.errors?.['minlength']) {
      return $localize`:@@groupPropertiesForm.nameMinLengthError:Name must be at least ${this.MIN_NAME_LENGTH}} characters long.`;
    }
    if (nameField?.errors?.['maxlength']) {
      return $localize`:@@groupPropertiesForm.nameMaxLengthError:Name cannot be longer than ${this.MAX_NAME_LENGTH} characters.`;
    }
    if (!nameField?.dirty && this.nameNotUniqueError) {
      return $localize`:@@groupPropertiesForm.nameNotUniqueError:Name must be unique in your company.`;
    }
    return '';
  }

  getDescriptionErrorText(): string | null {
    const descriptionField = this.form.get('description');
    if (descriptionField?.errors?.['maxlength']) {
      return $localize`:@@groupPropertiesForm.descriptionMaxLengthError:Description cannot be longer than ${this.MAX_DESCRIPTION_LENGTH} characters.`;
    }
    return null;
  }

  getVisibilityInfo() {
    return getVisibilityInfoText(this.hasCompanyVisibility || false);
  }

  get hasCompanyVisibility() {
    // Use raw value so that this gives the accurate value even if the control is disabled
    return this.form.getRawValue().hasCompanyVisibility;
  }

  get submitButtonDisabled() {
    // On the new group page, we only enable submit if there are no errors
    return this.loading || !this.form.dirty || this.form.invalid;
  }

  hasError(fieldName: string): boolean {
    const field = this.form.get(fieldName);
    if (!field) {
      console.error('Unknown field name:', fieldName);
      return true;
    }

    if (!field.dirty && fieldName == 'name' && this.nameNotUniqueError) {
      return true;
    }

    if (!field.dirty && !field.touched) {
      return false;
    }

    return !field.valid;
  }

  onSubmit(target: NavigationTarget) {
    this.nameNotUniqueError = false;

    const value = this.form.value;
    this.submit.emit({
      properties: {
        display_name: value.name ?? '',
        description: value.description ?? '',
        visibility: value.hasCompanyVisibility ? 'company' : 'private',
      },
      target,
    });

    this.form.markAsPristine();
  }

  onCancel() {
    this.location.back();
  }
}
