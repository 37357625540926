import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import { GroupPropertiesSubmitEvent } from '../model';
import { editGroupActions } from '../store/group-details/group-details.slice';
import { selectGroupDetails, selectGroups } from '../store/groups.feature';
import { GroupDto } from '../../api/dtos';
import { AsyncPipe } from '@angular/common';
import { GroupPropertiesFormComponent } from '../components/group-properties-form/group-properties-form.component';
import { BackLinkComponent } from '../../shared/components/back-link.component';

interface ViewModel {
  group: GroupDto;
  saving: boolean;
}

const selectViewModel = createSelector(
  selectGroups,
  selectGroupDetails,
  (groups, groupDetails): ViewModel | undefined => {
    const details = groupDetails;
    if (details) {
      const group = groups?.[details.id];
      if (group) {
        return { group, saving: details.savingProperties };
      }
    }
    return;
  }
);

@Component({
  selector: 'app-edit-group-properties',
  template: `
    <div class="margin-1">
      @if (viewModel$ | async; as viewModel) {
        <app-back-link></app-back-link>
        <h2 i18n="@@groupPropertiesForm.editGroupProperties">Edit Group Properties</h2>
        <app-group-properties-form
          [name]="viewModel.group.display_name"
          [description]="viewModel.group.description"
          [visibility]="viewModel.group.visibility"
          [customerName]="viewModel.group.customer_name"
          i18n-submitButton="@@groupPropertiesForm.saveChanges"
          submitButton="Save Changes"
          [cancelButton]="true"
          [loading]="viewModel.saving"
          (submit)="onSaveChanges($event)"
        >
        </app-group-properties-form>
      }
    </div>
  `,
  styles: [],
  imports: [BackLinkComponent, GroupPropertiesFormComponent, AsyncPipe],
})
export class EditGroupPropertiesComponent implements OnInit {
  private store = inject(Store);
  private groupId = inject(ActivatedRoute).snapshot.params['id'];
  readonly viewModel$ = this.store.select(selectViewModel);

  ngOnInit(): void {
    this.store.dispatch(editGroupActions.propertiesPageInitialized(this.groupId));
  }

  onSaveChanges(event: GroupPropertiesSubmitEvent) {
    this.store.dispatch(editGroupActions.updateGroupProperties(this.groupId, event.properties));
  }
}
