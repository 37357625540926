import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ButtonComponent } from '../../shared/components/button.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-leave-group-dialog',
  template: `<app-dialog [header]="getHeader()">
    <p>
      <span i18n="@@leaveGroup.warning"
        >If you leave the group, you will no longer be member until a manager adds you again.</span
      >
    </p>

    <div class="button-cell" slot="footer">
      <app-button (submit)="onCancel()" type="outline" i18n="@@cancel">Cancel</app-button>
      <app-button (submit)="onConfirmed()" type="filled" data-testid="confirm" i18n="@@leaveGroup"
        >Leave Group</app-button
      >
    </div>
  </app-dialog>`,
  styles: [
    `
      .button-cell {
        display: flex;
        gap: 16px;
        justify-content: right;
      }

      @media (max-width: 640px) {
        .button-cell {
          flex-direction: column;
        }
      }
    `,
  ],
  imports: [DialogComponent, ButtonComponent],
})
export class LeaveGroupDialogComponent {
  @Input() groupName = '';
  @Output() canceled = new EventEmitter();
  @Output() confirmed = new EventEmitter();

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  public show(): void {
    this.dialog.show();
  }

  public hide(): void {
    this.dialog.hide();
  }

  getHeader() {
    return $localize`:@@leaveGroup:Leave Group` + ': ' + this.groupName;
  }

  onCancel() {
    this.dialog.hide();
    this.canceled.emit();
  }

  onConfirmed() {
    this.dialog.hide();
    this.confirmed.emit();
  }
}
