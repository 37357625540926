import { Injectable, inject } from '@angular/core';
import { ErrorType } from '../global-error-handler';
import { AbstractErrorListener } from './error-listener';
import { StateService } from '../../store/state';

@Injectable({ providedIn: 'root' })
export class FallbackErrorListener extends AbstractErrorListener {
  private state = inject(StateService);

  override handleError(_error: ErrorType) {
    const message = $localize`:@@error.fallbackErrorMessage:Oops, something went wrong. We encountered an unknown error.`;
    this.state.error.set({ type: 'UnknownError', message });
  }
}
