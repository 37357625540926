import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, ViewChild } from '@angular/core';
import { GroupUser } from '../model';
import { UserSearchFieldComponent } from '../components/user-search-field/user-search-field.component';
import { TextAreaComponent } from '../../shared/components/text-area/text-area.component';
import { TemplateDirective } from '../../shared/directives/template.directive';
import { TextFieldComponent } from '../../shared/components/text-field/text-field.component';
import { IconAccountQuestion } from '../../shared/components/icons';
import { NgStyle } from '@angular/common';
import { TableDirective } from '../../shared/components/table/table.directive';
import { TableComponent } from '../../shared/components/table/table.component';
import { PillComponent } from '../../shared/components/pill.component';
import { ButtonComponent } from '../../shared/components/button.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';

export interface UserToAddOrInvite {
  type: 'add' | 'invite';
  user: GroupUser;
}

@Component({
  selector: 'app-add-members',
  template: `<app-dialog
    (onHide)="onHide()"
    i18n-header="@@addMembersDialog.addMembers"
    header="Add Members"
    width="60vw"
  >
    <div style="height: 60vh">
      <div class="container">
        <app-user-search-field
          id="add-member-search"
          i18n-placeholder="@@addMembersDialog.typeToSearchUser"
          placeholder="Type an email address or search for a user"
          (userSelected)="onUserAdded($event)"
          class="flex-grow-1"
        ></app-user-search-field>
        <app-button
          id="add-member-invite"
          class="button"
          (submit)="onUserInvited()"
          [disabled]="!isEmailAddress()"
          i18n="@@addMembersDialog.select"
        >
          Select
        </app-button>
      </div>
      <h6 i18n="@@addMembersDialog.selected">
        Selected: <app-pill>{{ selectedUsers.length }}</app-pill>
      </h6>
      @if (selectedUsers.length === 0) {
        <div i18n="@@addMembersDialog.noUserMessage">No user is selected.</div>
      } @else {
        <app-table id="add-member-selected" [values]="selectedUsers" [showPagination]="false" scrollHeight="24rem">
          <ng-template tableTemplate="header"
            ><tr>
              <th i18n="@@addMembersDialog.selectedList.name">Name</th>
              <th i18n="@@addMembersDialog.selectedList.email">Email Address</th>
              <th i18n="@@remove">Remove</th>
            </tr>
          </ng-template>
          <ng-template tableTemplate="body" let-user>
            <tr>
              <td [ngStyle]="{ width: '45%' }">
                @if (user.type === 'add') {
                  <div class="name">
                    <span>{{ user.user.name + (user.user.username ? ' (' + user.user.username + ')' : '') }}</span>
                  </div>
                } @else {
                  <div class="name">
                    <app-icon-account-question
                      i18n-title="@@addMembersDialog.willInviteUser"
                      title="The user will be invited via email"
                    ></app-icon-account-question>
                    <div class="name display-name">
                      <app-text-field
                        [(text)]="user.user.name"
                        i18n-placeholder="@@addMembersDialog.displayName"
                        placeholder="Display name"
                      >
                        @if (!isDisplayNameValid(user.user.name)) {
                          <ng-template appTemplate i18n="@@addMembersDialog.nameErrorMessage">
                            Name must not exceed 200 characters.
                          </ng-template>
                        }
                      </app-text-field>
                    </div>
                  </div>
                }
              </td>
              <td [ngStyle]="{ width: '45%' }">{{ user.user.email }}</td>
              <td class="td-center" [ngStyle]="{ width: '10%' }">
                <syn-icon-button
                  name="delete"
                  size="medium"
                  data-testid="delete"
                  i18n-label="@@remove"
                  label="Remove"
                  (click)="onRemoveUser(user)"
                ></syn-icon-button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      }
      @if (hasUsersToInvite()) {
        <h6 class="invitation" i18n="@@addMembersDialog.invitationHeader">
          Invitation for users without SICK ID or outside your company:
        </h6>
        <app-text-area
          i18n-placeholder="@@addMembersDialog.additionalMessage"
          placeholder="Additional message"
          [error]="getInvitationTextError()"
          [(text)]="invitationText"
        >
          <ng-template appTemplate i18n="@@addMembersDialog.invitationErrorMessage"
            >The invitation text is not allowed to exceed 2000 characters.</ng-template
          >
        </app-text-area>
      }
    </div>
    <div class="flex-end-1" slot="footer">
      <app-button id="add-member-cancel" type="outline" (submit)="onCancelDialog()" i18n="@@cancel">Cancel</app-button>
      <app-button
        id="add-member-submit"
        (submit)="onSubmitDialog()"
        [disabled]="!isDialogSubmittable()"
        i18n="@@addMembersDialog.addMembers"
        >Add Members</app-button
      >
    </div>
  </app-dialog>`,
  styles: [
    `
      .container {
        display: flex;
        width: 100%;
        column-gap: 1rem;
        margin-block: 0rem;
      }

      app-text-area {
        height: 9rem;
      }

      .button {
        min-width: 10rem;
      }

      .invitation {
        margin-bottom: 0.4rem;
      }

      .name {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    DialogComponent,
    UserSearchFieldComponent,
    ButtonComponent,
    PillComponent,
    TableComponent,
    TableDirective,
    NgStyle,
    IconAccountQuestion,
    TextFieldComponent,
    TemplateDirective,
    TextAreaComponent,
  ],
})
export class AddMembersComponent {
  selectedUsers: UserToAddOrInvite[] = [];
  invitationText: string = '';

  @Output() submit = new EventEmitter<UserToAddOrInvite[]>();

  @ViewChild(UserSearchFieldComponent) userSearchField!: UserSearchFieldComponent;
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  onHide() {
    this.selectedUsers = [];
    this.invitationText = '';
    this.userSearchField.clear();
  }

  onCancelDialog() {
    this.dialog.hide();
  }

  onRemoveUser(user: UserToAddOrInvite) {
    this.selectedUsers = this.selectedUsers.filter((u) => u !== user);
  }

  onUserAdded(user: GroupUser) {
    const alreadyAdded = this.selectedUsers.find((u) => u.type === 'add' && u.user.id === user.id);
    if (!alreadyAdded) {
      this.selectedUsers.push({ type: 'add', user });
    }
  }

  onUserInvited() {
    const email = this.userSearchField.getText();
    const alreadyInvited = this.selectedUsers.find((u) => u.type === 'invite' && u.user.email === email);
    if (!alreadyInvited) {
      this.selectedUsers.push({
        type: 'invite',
        user: { id: '', isVisible: false, name: '', email },
      });
    }
    this.userSearchField.clear();
  }

  onSubmitDialog() {
    this.submit.emit(this.selectedUsers);
    this.dialog.hide();
  }

  isEmailAddress() {
    const text = this.userSearchField?.getText();
    const at = text?.indexOf('@');
    // @ should not be at the start or end of the text
    return at > 0 && at < text.length - 1;
  }

  hasUsersToInvite() {
    return this.selectedUsers.find((u) => u.type === 'invite') !== undefined;
  }

  isDisplayNameValid(name: string) {
    return name.length <= 200;
  }

  isInvitationTextValid() {
    return this.invitationText.length <= 2000;
  }

  getInvitationTextError(): string | null {
    if (!this.isInvitationTextValid()) {
      return null;
    }

    return $localize`:@@addMembersDialog.invitationErrorMessage:The invitation text is not allowed to exceed 2000 characters.`;
  }

  isDialogSubmittable() {
    return this.selectedUsers.length > 0 && this.isInvitationTextValid();
  }
}
