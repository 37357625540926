import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { PageChangeEvent } from '../../group-list/group-list.component';
import { PermissionChangeDto, PermissionName } from '../../../api/dtos';
import { GroupPermission } from '../../model';
import { TableComponent } from '../../../shared/components/table/table.component';
import { ButtonComponent } from '../../../shared/components/button.component';
import { PermissionDropdownComponent } from '../permission-dropdown/permission-dropdown.component';
import { TableDirective } from '../../../shared/components/table/table.directive';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styles: [
    `
      .external-user {
        font-style: italic;
      }
      .permission-dropdown {
        margin: 0.7em 0 0.7em 0;
      }
    `,
  ],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [TableComponent, TableDirective, PermissionDropdownComponent, ButtonComponent],
})
export class PermissionListComponent {
  @Input() permissions: GroupPermission[] = [];
  @Input() rowCount = 10;
  @Input() totalCount = 0;
  @Input() loading = false;
  @Output() pageChange = new EventEmitter<PageChangeEvent>();
  @Output() changePermission = new EventEmitter<PermissionChangeDto>();
  @ViewChild(TableComponent) table!: TableComponent<unknown>;

  dropdownValueOf(permissions: PermissionName[]): PermissionName {
    // We currently reduce ['manage-group', 'view-group'] to just Manager
    // Just ['view-group'] is displayed as Viewer
    if (permissions.length === 1) {
      return permissions[0];
    }
    if (permissions.length === 2) {
      if (permissions.find((p) => p === 'manage-group')) {
        return 'manage-group';
      }
    }
    return 'view-group';
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (event.first !== undefined) {
      this.pageChange.emit({ offset: event.first });
    }
  }

  onPermissionChange(permission: PermissionName, currentPermission: GroupPermission) {
    const currentValue = this.dropdownValueOf(currentPermission.permissions);
    if (permission === currentValue) {
      // No change
      return;
    }

    const user_id = currentPermission.user.id;
    const changeEvent: PermissionChangeDto = { add: [], remove: [] };
    if (permission === 'manage-group') {
      changeEvent.add = [
        { user_id, name: 'manage-group' },
        { user_id, name: 'view-group' },
      ];
    } else {
      changeEvent.add = [{ user_id, name: 'view-group' }];
      changeEvent.remove = [{ user_id, name: 'manage-group' }];
    }

    this.changePermission.emit(changeEvent);
  }

  onRevokeAllPermission(permission: GroupPermission) {
    const user_id = permission.user.id;
    const changeEvent: PermissionChangeDto = {
      add: [],
      remove: [
        { user_id, name: 'manage-group' },
        { user_id, name: 'view-group' },
      ],
    };
    this.changePermission.emit(changeEvent);
  }

  isEditable(permission: GroupPermission): boolean {
    return !permission.isCurrentUser && permission.user.isVisible;
  }

  texts = {
    change: $localize`:@@groupDetails.permissionsTable.dropdown:Change permission`,
    revokeAll: $localize`:@@groupDetails.permissionsTable.revokeAllPermissions:Revoke All Permissions`,
    currentUser: $localize`:@@groupDetails.permissionsTable.disabled.current:You cannot edit your own permissions`,
    invisibleUser: $localize`:@@groupDetails.permissionsTable.disabled.invisible:Permissions of unknown users cannot be edited`,
  };

  getTitleText(permission: GroupPermission, fallback: string): string {
    if (permission.isCurrentUser) {
      return this.texts.currentUser;
    }
    if (!permission.user.isVisible) {
      return this.texts.invisibleUser;
    }
    return fallback;
  }
}
