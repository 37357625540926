import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionName } from 'src/app/api/dtos';

@Component({
  selector: 'app-permission-dropdown',
  template: `<syn-select
    [attr.value]="value"
    max-options-visible="2"
    hoist="true"
    (syn-change)="onSelected($event)"
    [attr.disabled]="editable ? null : true"
  >
    <syn-option value="view-group" i18n="@@permissionNames.view-group">View</syn-option>
    <syn-option value="manage-group" i18n="@@permissionNames.manage-group">Manage</syn-option>
  </syn-select>`,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
})
export class PermissionDropdownComponent {
  @Input() value: PermissionName = 'view-group';
  @Output() valueChange = new EventEmitter<PermissionName>();
  @Input() editable = true;

  onSelected(event: Event) {
    const newValue = (event.target as HTMLSelectElement).value as PermissionName;
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
