import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `<syn-dialog
    [style.--width]="width"
    [attr.label]="header"
    (syn-show)="onShow($event)"
    (syn-hide)="onHide($event)"
    #dialog
  >
    <ng-content></ng-content>
  </syn-dialog>`,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogComponent {
  @Input() width: string | null = null;
  @Input() header = '';
  @Output('onHide') onHideEmitter = new EventEmitter();
  @Output('onShow') onShowEmitter = new EventEmitter();

  @ViewChild('dialog') dialog!: ElementRef;

  public show(): void {
    this.dialog.nativeElement.show();
  }

  public hide(): void {
    this.dialog.nativeElement.hide();
  }

  onHide(event: Event) {
    // syn-hide is a global event: We have to check for the target
    if (event.target === this.dialog.nativeElement) {
      this.onHideEmitter.emit(event);
    }
  }

  onShow(event: Event) {
    // syn-show is a global event: We have to check for the target
    if (event.target === this.dialog.nativeElement) {
      this.onShowEmitter.emit(event);
    }
  }
}
