import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild, computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { computeGroupViewModel } from '../group-details/group-details.component';
import { PageChangeEvent } from '../group-list/group-list.component';
import { InvitationInputListDto, PermissionChangeDto, PermissionInputDto } from '../../api/dtos';
import { GroupUser } from '../model';
import {
  GrantedPermissionsInfo,
  editGroupActions,
  invitationListActions,
  memberListActions,
  permissionListActions,
} from '../store/group-details/group-details.slice';
import { PermissionListComponent } from '../components/permission-list/permission-list.component';
import { MemberListComponent } from '../components/member-list/member-list.component';
import { AddMembersComponent, UserToAddOrInvite } from '../add-members/add-members.component';
import { SearchFieldComponent } from 'src/app/shared/components/search-field/search-field.component';
import { GrantPermissionsComponent, UserAndPermission } from '../grant-permissions/grant-permissions.component';
import { InvitationListComponent } from '../components/invitation-list/invitation-list.component';
import { InlineNotificationComponent } from '../../shared/components/inline-notification';
import { AddMembersFeedbackComponent } from '../add-members-feedback/add-members-feedback.component';
import { ButtonComponent } from '../../shared/components/button.component';
import { SearchFieldComponent as SearchFieldComponent_1 } from '../../shared/components/search-field/search-field.component';
import { GroupDetailsPropertiesComponent } from '../components/group-details-properties/group-details-properties.component';
import { BackLinkComponent } from '../../shared/components/back-link.component';
import { PillComponent } from '../../shared/components/pill.component';
import { selectGroupDetails, selectGroups, selectUsers } from '../store/groups.feature';
import { StateService } from '../../shared/store/state';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    BackLinkComponent,
    GroupDetailsPropertiesComponent,
    SearchFieldComponent_1,
    ButtonComponent,
    AddMembersComponent,
    AddMembersFeedbackComponent,
    MemberListComponent,
    InlineNotificationComponent,
    GrantPermissionsComponent,
    PermissionListComponent,
    InvitationListComponent,
    PillComponent,
  ],
})
export class EditGroupComponent implements OnInit {
  private store = inject(Store);
  private state = inject(StateService);
  private groupId = inject(ActivatedRoute).snapshot.params['id'];

  groups = this.store.selectSignal(selectGroups);
  users = this.store.selectSignal(selectUsers);
  groupDetails = this.store.selectSignal(selectGroupDetails);
  viewModel = computed(() =>
    computeGroupViewModel(this.groups(), this.users(), this.groupDetails(), this.state.authUser())
  );

  hasInvitedUsers = false;

  @ViewChild('memberSearchField') memberSearchField?: SearchFieldComponent;
  @ViewChild('permissionSearchField') permissionSearchField?: SearchFieldComponent;
  @ViewChild(AddMembersComponent) addMembersDialog!: AddMembersComponent;
  @ViewChild(AddMembersFeedbackComponent) addMembersFeedback!: AddMembersFeedbackComponent;
  @ViewChild(GrantPermissionsComponent) grantPermissionsDialog!: GrantPermissionsComponent;

  ngOnInit(): void {
    this.store.dispatch(editGroupActions.pageInitialized(this.groupId));
  }

  getInvitationsLabel(count: number): string {
    return $localize`:@@groupDetails.tabs.invitations:Invitations (${count})`;
  }

  clearSearchFields() {
    // Clear the filter field if members are added or removed
    // We cannot keep the filter, since we have to reload the complete group data
    // Some permissions may have changed along with the membership status
    this.memberSearchField?.clear();
    this.permissionSearchField?.clear();
  }

  onAddMembers(users: GroupUser[]) {
    const userIds = users.map((user) => user.id);
    this.store.dispatch(editGroupActions.addMembers(this.groupId, userIds));
    this.clearSearchFields();
  }

  openAddMembersDialog() {
    this.addMembersDialog.dialog.show();
  }

  openGrantPermissionsDialog() {
    this.grantPermissionsDialog.dialog.show();
  }

  getGrantedPermissionsInfo(info: GrantedPermissionsInfo | undefined): string {
    if (info && info.granted > 0) {
      return $localize`:@@groupDetails.grantedPermissionsInfo.granted:Granted permissions to ${info.granted} user(s).`;
    }
    if (info && info.alreadyGranted > 0) {
      return $localize`:@@groupDetails.grantedPermissionsInfo.someNotGranted:Did not grant permissions to ${info.alreadyGranted} user(s), since they were already granted.`;
    }
    return '';
  }

  onAddOrInviteMembers(users: UserToAddOrInvite[]) {
    const userIdsToAdd = users.filter((u) => u.type === 'add').map((u) => u.user.id);

    const invite: InvitationInputListDto = {
      invitations: users
        .filter((u) => u.type === 'invite')
        .map((u) => ({
          display_name: u.user.name,
          email: u.user.email || '',
          free_text: this.addMembersDialog.invitationText,
          types: ['member', 'view-group'],
        })),
    };

    this.store.dispatch(editGroupActions.addAndInviteMembers(this.groupId, userIdsToAdd, invite));

    this.clearSearchFields();

    this.hasInvitedUsers = invite.invitations.length > 0;
    this.addMembersFeedback.dialog.show();
  }

  onRemoveGrantedPermissionsInfo() {
    this.store.dispatch(editGroupActions.removeGrantedPermissionsInfo(this.groupId));
  }

  onAddMembersResultsConfirmed() {
    this.store.dispatch(editGroupActions.clearAddedMembers());
  }

  onRemoveMember(userId: string) {
    this.store.dispatch(editGroupActions.removeMembers(this.groupId, [userId]));
    this.clearSearchFields();
  }

  onGrantPermissions(grants: UserAndPermission[]) {
    const add = grants.reduce<PermissionInputDto[]>((list, grant) => {
      // Managers get permissions to manage and view the group
      const permissions: PermissionInputDto[] = [{ name: 'view-group', user_id: grant.user.id }];
      if (grant.permission === 'manage-group') {
        permissions.push({ name: 'manage-group', user_id: grant.user.id });
      }
      return list.concat(permissions);
    }, []);
    this.store.dispatch(editGroupActions.changePermission(this.groupId, { add, remove: [] }, true));
  }

  onChangePermission(change: PermissionChangeDto) {
    this.store.dispatch(editGroupActions.changePermission(this.groupId, change, false));
  }

  onRemoveInvitation(code: string) {
    this.store.dispatch(editGroupActions.deleteInvitations(this.groupId, code));
  }

  onEditGroupProperties() {
    // Navigate to the edit group properties page
    this.store.dispatch(editGroupActions.editGroupProperties(this.groupId));
  }

  onMemberPageChange(event: PageChangeEvent) {
    this.store.dispatch(memberListActions.offsetChanged(event.offset));
  }

  onInvitationPageChange(event: PageChangeEvent) {
    this.store.dispatch(invitationListActions.offsetChanged(event.offset));
  }

  onPermissionPageChange(event: PageChangeEvent) {
    this.store.dispatch(permissionListActions.offsetChanged(event.offset));
  }

  @ViewChild(MemberListComponent) memberList!: MemberListComponent;

  onFilterMembers(text: string) {
    this.store.dispatch(memberListActions.filterChanged(this.groupId, text.trim()));
    // We have to reset the table to the first page if the user navigated to other pages
    this.memberList.table.first = 0;
  }

  @ViewChild(PermissionListComponent) permissionList!: PermissionListComponent;

  onFilterPermissions(text: string) {
    this.store.dispatch(permissionListActions.filterChanged(this.groupId, text.trim()));
    // We have to reset the table to the first page if the user navigated to other pages
    this.permissionList.table.first = 0;
  }
}
