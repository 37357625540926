import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { GroupId, GroupVisibility } from '../model';
import { GroupDto } from '../../api/dtos';
import { IconComponent } from '../../shared/components/icon.component';
import { RouterLink } from '@angular/router';
import { TableDirective } from '../../shared/components/table/table.directive';
import { TableComponent } from '../../shared/components/table/table.component';
import { DeleteGroupDialogComponent } from '../components/delete-group-dialog/delete-group-dialog';

export interface PageChangeEvent {
  offset: number;
}

export interface DeleteGroupEvent {
  groupId: GroupId;
}

const VISIBILITY_TOOLTIP: Record<GroupVisibility, string> = {
  company: $localize`:@@groupList.visibilityTooltip.company:Anyone in your company can see this group.`,
  private: $localize`:@@groupList.visibilityTooltip.private:Only users with appropriate rights can see this group.`,
};

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [DeleteGroupDialogComponent, TableComponent, TableDirective, RouterLink, IconComponent],
})
export class GroupListComponent {
  @Input() groups: GroupDto[] = [];
  @Input() rowCount = 0;
  @Input() totalCount = 0;
  @Input() loading = false;
  @Input() showDeleteButton = false;
  @Input() offset = 0;
  @Input() baseLink = 'managed-groups';

  @Output() onPageChange = new EventEmitter<PageChangeEvent>();
  @Output() onDelete = new EventEmitter<DeleteGroupEvent>();

  @ViewChild(DeleteGroupDialogComponent) deleteDialog!: DeleteGroupDialogComponent;
  @ViewChild(TableComponent) table!: TableComponent<unknown>;

  groupToDelete: GroupDto | undefined;

  onLazyLoad(event: LazyLoadEvent) {
    if (event.first !== undefined) {
      this.onPageChange.emit({ offset: event.first });
    }
  }

  onDeleteButtonClicked(group: GroupDto) {
    this.groupToDelete = group;
    this.deleteDialog.show();
  }

  closeDeleteModal() {
    this.deleteDialog.hide();
    this.groupToDelete = undefined;
  }

  onDeleteGroupConfirmed() {
    if (this.groupToDelete) {
      this.onDelete.emit({ groupId: this.groupToDelete.id });
    } else {
      console.error('onDeleteGroupConfirmed called without a groupToDelete');
    }

    this.closeDeleteModal();
  }

  getVisiblityIcon(visibility: GroupVisibility) {
    switch (visibility) {
      case 'company':
        return 'visibility';
      case 'private':
        return 'visibility_off';
    }
  }

  getVisibilityTooltip(visibility: GroupVisibility) {
    return VISIBILITY_TOOLTIP[visibility];
  }

  preventEventPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
