import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, ViewChild, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, RouterLink } from '@angular/router';
import { GroupVisibility } from '../../model';
import { groupListActions } from '../../store/groups.feature';
import { IconComponent } from '../../../shared/components/icon.component';
import { ButtonComponent } from '../../../shared/components/button.component';
import { DeleteGroupDialogComponent } from '../delete-group-dialog/delete-group-dialog';
import { getVisibilityInfoText } from '../group-properties-form/group-properties-form.component';

@Component({
  selector: 'app-group-details-properties',
  template: `
    <app-delete-group-dialog
      [groupName]="name"
      (confirmed)="onDeleteGroupConfirmed()"
      (canceled)="onCancelDeleteDialog()"
      #deleteDialog
    >
    </app-delete-group-dialog>
    <div class="container">
      <div class="header">
        <h2>{{ name }}</h2>
        @if (!readonly) {
          <app-button
            class="edit-button"
            type="text"
            icon="edit"
            i18n-title="@@groupDetails.details.editTooltip"
            title="Edit Group Properties"
            data-testid="edit"
            [routerLink]="['/managed-groups', id, 'edit']"
          ></app-button>
          <syn-icon-button
            class="edit-button"
            name="delete"
            size="medium"
            data-testid="delete"
            i18n-title="@@groupDetails.details.deleteTooltip"
            title="Delete Group"
            i18n-label="@@delete"
            label="Delete"
            (click)="onDeleteGroup()"
          ></syn-icon-button>
        }
      </div>
      <div class="group-details">
        <div class="label" i18n="@@groupDetails.details.customerName">Customer Name</div>
        <span>{{ customerName }}</span>
        <div class="label" i18n="@@groupDetails.details.description">Description</div>
        <span class="small-info-text">{{ description }}</span>
        <div class="label" i18n="@@groupDetails.details.visibility">Visibility</div>
        <div class="visibility-row">
          <app-icon [icon]="getVisibilityIcon(visibility)"></app-icon>
          <span class="small-info-text">
            {{ getVisibilityInfo() }}
          </span>
        </div>
        <div class="label" i18n="@@groupDetails.details.id">ID</div>
        <span>{{ id }}</span>
      </div>
    </div>
  `,
  styles: [
    `
      .header {
        display: flex;
        align-items: baseline;
        font-size: var(--syn-font-size-large);
      }

      .edit-button {
        align-self: center;
      }

      .group-details {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(auto, 115px) minmax(auto, 550px));
        gap: 16px 32px;
        width: 100%;
        align-items: first baseline;
        margin-block: 16px;
      }

      .label {
        padding-top: 8px;
        font-weight: bold;
      }

      .visibility-row {
        display: flex;
        align-items: baseline;
        column-gap: 16px;
      }
      .visibility-row span {
        margin-top: auto;
      }

      @media (max-width: 640px) {
        .group-details {
          grid-template-columns: auto;
        }
      }
    `,
  ],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [DeleteGroupDialogComponent, ButtonComponent, RouterLink, IconComponent],
})
export class GroupDetailsPropertiesComponent {
  @Input() customerName?: string;
  @Input() name = '';
  @Input() description = '';
  @Input() visibility: GroupVisibility = 'private';
  @Input() id = '';
  @Input() readonly = true;

  private store = inject(Store);
  private router = inject(Router);

  @ViewChild('deleteDialog') deleteDialog!: DeleteGroupDialogComponent;

  onDeleteGroup() {
    this.deleteDialog.show();
  }

  onCancelDeleteDialog() {
    this.deleteDialog.hide();
  }

  onDeleteGroupConfirmed() {
    this.store.dispatch(groupListActions.deletionRequested(this.id));
    this.deleteDialog.hide();
    // We do not await the promise here (void operator to suppress warning)
    void this.router.navigate(['..']);
  }

  hasCompanyVisibility(visibility: GroupVisibility): boolean {
    return visibility === 'company';
  }

  getVisibilityInfo() {
    return getVisibilityInfoText(this.visibility === 'company');
  }

  getVisibilityIcon(visibility: GroupVisibility): string {
    switch (visibility) {
      case 'company':
        return 'visibility';
      case 'private':
        return 'visibility_off';
    }
  }
}
