import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, computed, inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { StateService } from '../../store/state';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-error-banner',
  template: ` <syn-alert
    @transitionTrigger
    variant="danger"
    [attr.open]="openError() ? true : null"
    data-testid="app-error-banner-callout"
  >
    <syn-icon slot="icon" name="error"></syn-icon>
    {{ openError()?.message }}
  </syn-alert>`,
  styles: [],
  animations: [
    trigger('transitionTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)', transformOrigin: '50% 0' }),
        animate('300ms ease-in-out', style({ opacity: 1, transform: 'scaleY(1)' })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ErrorBannerComponent implements OnInit {
  private state = inject(StateService);
  private router = inject(Router);
  openError = computed(() => this.state.error());

  ngOnInit(): void {
    // The error banner is cleared when the user navigates to another page
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.state.error.set(null);
      }
    });
  }
}
