import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../button.component';

@Component({
  selector: 'app-page-not-found',
  template: `<div class="container">
    <div class="not-found-message">
      <h2 i18n="@@notFoundPage.message">Oops! We couldn't find the page you were looking for.</h2>

      <div class="button-container">
        <app-button routerLink="/" i18n="@@notFoundPage.goToHomeButton">Go To My Groups</app-button>
        <app-button type="outline" (click)="navigateBack()" i18n="@@notFoundPage.goBackButton">Go Back</app-button>
      </div>
    </div>
  </div>`,
  styles: [
    `
      .container {
        margin: 1rem;
        padding: 4rem;
        display: flex;
        gap: 2rem;
        align-items: center;
      }

      .button-container {
        display: inline-flex;
        gap: 2rem;
      }

      .icon {
        fill: #bcbcbc;
      }
    `,
  ],
  standalone: true,
  imports: [ButtonComponent, RouterLink],
})
export class PageNotFoundComponent {
  private location = inject(Location);

  navigateBack() {
    this.location.back();
  }
}
