import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { SupportRequestDto, SupportRequestResult } from '../../../api/dtos';
import { GroupHttpApi } from '../../../api/http';
import { ToggleGroupOption, ToggleGroupComponent } from '../toggle-group/toggle-group.component';
import { SupportRequestType, SUPPORT_REQUEST_TYPES, processSupportRequestText } from './model';
import { ButtonComponent } from '../button.component';
import { SpinnerComponent } from '../spinner.component';
import { InlineNotificationComponent } from '../inline-notification';
import { TextAreaComponent } from '../text-area/text-area.component';
import { DialogComponent } from '../dialog/dialog.component';

type ModalState = 'idle' | 'sending' | SupportRequestResult;

type SupportRequestTypeViewData = Record<
  SupportRequestType,
  {
    placeholder: string;
    displayName: string;
    description: string;
  }
>;

const requestTypeData: SupportRequestTypeViewData = {
  'orphaned-group': {
    description: $localize`:@@contactSupport.orphanedGroup.description:Please provide a brief explanation of why you require management access to the group.`,
    displayName: $localize`:@@contactSupport.orphanedGroup.name:I require management access to this group.`,
    placeholder: $localize`:@@contactSupport.orphanedGroup.placeholder:State your explanation here.`,
  },
  feedback: {
    description: $localize`:@@contactSupport.feedback.description:Feel free to share feedback or offer suggestions for new features. We are eager to hear your thoughts and ideas. Thank you for helping us enhance SICK ID Group Management to better meet your needs.`,
    displayName: $localize`:@@contactSupport.feedback.name:I want to share suggestions for improvement.`,
    placeholder: $localize`:@@contactSupport.feedback.placeholder:Enter your feedback here.`,
  },

  'other-issue': {
    description: $localize`:@@contactSupport.otherIssue.description:Tell us about this issue.`,
    displayName: $localize`:@@contactSupport.otherIssue.name:I need assistance with a technical issue.`,
    placeholder: $localize`:@@contactSupport.otherIssue.placeholder:Include any details we can use to help you.`,
  },
};

@Component({
  selector: 'app-contact-support',
  template: `
    <ng-container>
      <syn-icon-button
        i18n-title="@@contactSupport.tooltip"
        title="Contact Support"
        id="contact"
        color="neutral"
        name="help"
        label="Contact"
        (click)="show($event)"
        #showButton
      ></syn-icon-button>
      <app-dialog
        i18n-header="@@contactSupport.dialogHeader"
        header="How Can We Help?"
        width="50vw"
        (onShow)="onShowModal()"
      >
        <div id="container">
          <section id="main">
            <p i18n="@@contactSupport.introduction">
              If you are encountering any challenges or have questions, please specify your request type and provide
              further details in the text area below.
            </p>
            <app-toggle-group
              [options]="toggleGroupOptions()"
              [value]="selectedRequestType()"
              (select)="onChange($event)"
              id="toggle-group"
            ></app-toggle-group>
            <p>{{ requestDescription() }}</p>
            <app-text-area
              id="request"
              [(text)]="requestText"
              [size]="'small'"
              [rows]="8"
              [disabled]="sendingRequest() || requestSucceeded()"
              [maxLength]="characterLimit"
              [error]="textLimitExceeded()"
              [placeholder]="placeholder()"
            >
            </app-text-area>
            <div small id="character-counter">{{ characterCount() }} / {{ characterLimit }}</div>
          </section>
          <section id="feedback">
            @if (requestFailed()) {
              <app-inline-notification
                [variant]="'danger'"
                [animated]="false"
                [closeable]="false"
                i18n="@@contactSupport.result.requestFailed"
              >
                Your request could not be sent. Please try again later.
              </app-inline-notification>
            }
            @if (requestSucceeded()) {
              <app-inline-notification
                [attr.variant]="'success'"
                [animated]="false"
                [closeable]="false"
                i18n="@@contactSupport.result.requestSucceeded"
              >
                Thank you for your request. We will do our best to assist you as quickly as possible.
              </app-inline-notification>
            }
            @if (sendingRequest()) {
              <app-spinner i18n-label="@@contactSupport.sendingRequest" label="Sending your request ..."></app-spinner>
            }
          </section>
        </div>
        <div slot="footer" class="flex-end-1">
          @if (!requestSucceeded()) {
            <app-button id="cancel" type="outline" (submit)="closeModal()" i18n="@@cancel"> Cancel</app-button>
          }
          @if (!requestSucceeded()) {
            <app-button id="submit" (submit)="submitRequest()" [disabled]="!submittable" i18n="@@submit">
              Submit
            </app-button>
          }
          @if (requestSucceeded()) {
            <app-button id="close" (submit)="closeModal()" i18n="@@close">Close</app-button>
          }
        </div>
      </app-dialog>
    </ng-container>
  `,
  styles: [
    `
      #container {
        display: flex;
        flex-direction: column;
        height: 60vh;
        font-size: var(--syn-font-size-medium);
      }

      #main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      #toggle-group {
        margin-block-end: 1rem;
      }

      #request {
        margin-bottom: 0.5rem;
      }

      #character-counter {
        margin-inline-start: auto;
        font-size: var(--syn-font-size-small);
        color: var(--syn-color-neutral-500);
      }

      #feedback {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-block: 1rem;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    DialogComponent,
    ToggleGroupComponent,
    TextAreaComponent,
    InlineNotificationComponent,
    SpinnerComponent,
    ButtonComponent,
  ],
})
export class ContactSupportComponent {
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  readonly characterLimit = 2000;

  private groupApi = inject(GroupHttpApi);

  modalState: WritableSignal<ModalState> = signal('idle');
  selectedRequestType: WritableSignal<SupportRequestType> = signal('other-issue');
  requestText = signal('');
  availableRequestTypes: WritableSignal<SupportRequestType[]> = signal([]);

  @ViewChild('showButton') showButton: ElementRef | undefined;

  isGroupSelected() {
    return (
      window.location.pathname.startsWith('/managed-groups/') ||
      window.location.pathname.startsWith('/visible-groups/') ||
      window.location.pathname.startsWith('/my-groups/')
    );
  }

  toggleGroupOptions = computed((): ToggleGroupOption<SupportRequestType>[] => {
    return this.availableRequestTypes().map((type) => ({
      value: type,
      displayText: requestTypeData[type].displayName,
    }));
  });

  placeholder = computed(() => {
    return requestTypeData[this.selectedRequestType()].placeholder;
  });

  requestDescription = computed(() => {
    return requestTypeData[this.selectedRequestType()].description;
  });

  characterCount = computed(() => {
    return this.requestText().length;
  });

  requestSucceeded = computed(() => {
    return this.modalState() === 'success';
  });

  requestFailed = computed(() => {
    return this.modalState() === 'failure';
  });

  sendingRequest = computed(() => {
    return this.modalState() === 'sending';
  });

  submittable = computed(() => {
    const count = this.characterCount();
    return count >= 5 && count <= this.characterLimit && !this.sendingRequest();
  });

  textLimitExceeded = computed(() => {
    if (this.characterCount() <= this.characterLimit) {
      return null;
    }
    return $localize`:@@contactSupport.limitExceeded:The request text exceeds the maximum allowed length.`;
  });

  public show(event: Event): void {
    if (event.target === this.showButton?.nativeElement) {
      this.dialog.show();
    }
  }

  onShowModal() {
    const availableRequestTypes = [...SUPPORT_REQUEST_TYPES].filter(
      (type) => this.isGroupSelected() || type !== 'orphaned-group'
    );
    this.availableRequestTypes.set(availableRequestTypes);
    this.selectedRequestType.set(availableRequestTypes[0]);
  }

  closeModal(): void {
    this.requestText.set('');
    this.modalState.set('idle');
    this.dialog.hide();
  }

  onChange(type: SupportRequestType) {
    this.selectedRequestType.set(type);
    console.log('Text', this.requestText());
  }

  submitRequest(): void {
    this.modalState.set('sending');
    const request = this.createRequest();

    this.groupApi.createSupportRequest(request).subscribe({
      next: () => this.modalState.set('success'),
      error: () => this.modalState.set('failure'),
    });
  }

  private createRequest(): SupportRequestDto {
    return {
      path: window.location.pathname,
      host: window.location.host,
      request: processSupportRequestText(this.selectedRequestType(), this.requestText()),
      type: this.selectedRequestType(),
    };
  }
}
