import { Route } from '@angular/router';
import { EditGroupPropertiesComponent } from './groups/edit-group-properties/edit-group-properties.component';
import { EditGroupComponent } from './groups/edit-group/edit-group.component';
import { GroupDetailsComponent } from './groups/group-details/group-details.component';
import { InvitationComponent } from './groups/invitation/invitation.component';
import { NewGroupComponent } from './groups/new-group/new-group.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { GroupsOverviewComponent } from './groups/groups-overview/groups-overview.component';

export const routes: Route[] = [
  { path: '', redirectTo: 'managed-groups', pathMatch: 'full' },
  {
    path: 'my-groups',
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        component: GroupDetailsComponent,
      },
    ],
  },
  {
    path: 'managed-groups',
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        children: [
          { path: '', component: EditGroupComponent },
          {
            path: 'edit',
            title: $localize`:@@routes.editGroupProperties:Group Management | Edit Group Properties`,
            component: EditGroupPropertiesComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'visible-groups',
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        component: GroupDetailsComponent,
      },
    ],
  },
  {
    path: 'new',
    component: NewGroupComponent,
  },
  {
    path: 'invitation',
    component: InvitationComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];
