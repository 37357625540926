{
  "locale": "en-US",
  "translations": {
    "routes.myGroups": "Group Management | Membership",
    "routes.managedGroups": "Group Management | Management",
    "routes.editGroupProperties": "Group Management | Edit Group Properties",
    "routes.visibleGroups": "Group Management | All Visible",
    "routes.newGroup": "Group Management | New Group",
    "routes.invitation": "Group Management | Invitation",
    "addMembersFeedback.members": "Members",
    "addMembersFeedback.invitations": "Invitations",
    "addMembersFeedback.details.table.alias": " Display Name ",
    "addMembersFeedback.details.table.email": "Email",
    "addMembersFeedback.details.table.result": " Invitation ",
    "addMembersFeedback.details.table.placeholder": "(No Display Name)",
    "addMembersFeedback.sendingInvitations": "Sending Invitations...",
    "addMembersFeedback.confirm": " Confirm ",
    "addMembersFeedback.details.result.success": "Succeeded",
    "addMembersFeedback.details.result.failure": "Failed",
    "addMembersFeedback.infoAddedMembers": "Added {$PH} member(s).",
    "addMembersFeedback.infoSomeMembersNotAdded": "Did not add {$PH} membe(s).",
    "addMembersDialog.addMembers": "Add Members",
    "addMembersDialog.typeToSearchUser": "Type an email address or search for a user",
    "addMembersDialog.select": " Select ",
    "addMembersDialog.selected": " Selected: {$START_TAG_APP_PILL}{$INTERPOLATION}{$CLOSE_TAG_APP_PILL}",
    "addMembersDialog.noUserMessage": "No user is selected.",
    "addMembersDialog.selectedList.name": "Name",
    "addMembersDialog.selectedList.email": "Email Address",
    "remove": "Remove",
    "addMembersDialog.willInviteUser": "The user will be invited via email",
    "addMembersDialog.displayName": "Display name",
    "addMembersDialog.nameErrorMessage": " Name must not exceed 200 characters. ",
    "addMembersDialog.invitationHeader": " Invitation for users without SICK ID or outside your company: ",
    "addMembersDialog.additionalMessage": "Additional message",
    "addMembersDialog.invitationErrorMessage": "The invitation text is not allowed to exceed 2000 characters.",
    "cancel": "Cancel",
    "groupList.deleteModal.warning": "Deleting the group is permanent and cannot be reversed.",
    "groupList.deleteModal.proceedQuestion": "Are you sure, you want to proceed?",
    "groupList.deleteModal.deleteGroupPermanently": " Delete Group Permanently ",
    "groupList.deleteModal.header": "Delete Group {$PH}",
    "groupDetails.details.editTooltip": "Edit Group Properties",
    "groupDetails.details.deleteTooltip": "Delete Group",
    "delete": "Delete",
    "leaveGroup": "Leave Group",
    "groupDetails.details.customerName": "Customer Name",
    "groupDetails.details.description": "Description",
    "groupDetails.details.visibility": "Visibility",
    "groupDetails.details.id": "ID",
    "groupPropertiesForm.customer": "Customer Name",
    "groupPropertiesForm.name": "Group Name",
    "groupPropertiesForm.description": "Description",
    "groupPropertiesForm.visibility": "Visibility",
    "groupDetails.details.visibility-company-info": "Anyone in your company and anyone authorised to view and manage will be able to view this group.",
    "groupDetails.details.visibility-private-info": "This group is only visible to its members, managers, and anyone with permission to view it. Other users in your company CANNOT view this group.",
    "groupPropertiesForm.nameMinLengthError": "Name must be at least {$PH}} characters long.",
    "groupPropertiesForm.nameMaxLengthError": "Name cannot be longer than {$PH} characters.",
    "groupPropertiesForm.nameNotUniqueError": "Name must be unique in your company.",
    "groupPropertiesForm.descriptionMaxLengthError": "Description cannot be longer than {$PH} characters.",
    "leaveGroup.warning": "If you leave the group, you will no longer be member until a manager adds you again.",
    "permissionNames.view-group": "View",
    "permissionNames.member": "Member",
    "permissionNames.manage-group": "Manage",
    "groupDetails.permissionsTable.name": "Name",
    "groupDetails.permissionsTable.email": "Email",
    "groupDetails.permissionsTable.permission": "Permission",
    "groupDetails.permissionsTable.revokeAllPermissions": "Revoke All Permissions",
    "groupDetails.externalUser": "External User",
    "groupDetails.permissionsTable.dropdown": "Change permission",
    "groupDetails.permissionsTable.disabled.current": "You cannot edit your own permissions",
    "groupDetails.permissionsTable.disabled.invisible": "Permissions of unknown users cannot be edited",
    "groupDetails.invitationsTable.email": "Email Address",
    "groupDetails.invitationsTable.invitedAs": "Invitation Purpose",
    "groupDetails.invitationsTable.name": "Name",
    "groupDetails.invitationsTable.status": "Status",
    "groupDetails.invitationsTable.sentOn": "Sent On",
    "groupDetails.invitationsTable.response.accepted": "Accepted",
    "groupDetails.invitationsTable.response.declined": "Declined",
    "groupDetails.invitationsTable.response.pending": "Pending",
    "userSearch.timeoutError": "The user search took too long. Please modify your search text to try again.",
    "groupPropertiesForm.editGroupProperties": "Edit Group Properties",
    "groupPropertiesForm.saveChanges": "Save Changes",
    "groupDetails.tabs.members": "Members",
    "groupDetails.details.searchMembers": "Search Members",
    "groupDetails.addMembersButton": " Add Members ",
    "groupDetails.tabs.permissions": "Permissions",
    "groupDetails.details.filterPermissions": "Search Permissions",
    "groupDetails.addPermissionsButton": "Grant Permissions",
    "groupDetails.tabs.invitations": "Invitations",
    "groupDetails.grantedPermissionsInfo.granted": "Granted permissions to {$PH} user(s).",
    "groupDetails.grantedPermissionsInfo.someNotGranted": "Did not grant permissions to {$PH} user(s), since they were already granted.",
    "grantPermissionsDialog.grantPermissions": "Grant Permissions",
    "grantPermissionsDialog.typeToSearchUser": "Type an email address or search for a user",
    "grantPermissionsDialog.selected": " Selected: {$START_TAG_APP_PILL}{$INTERPOLATION}{$CLOSE_TAG_APP_PILL}",
    "grantPermissionsDialog.noUserMessage": "No user is selected.",
    "grantPermissionsDialog.selectedList.name": "Name",
    "grantPermissionsDialog.selectedList.email": "Email Address",
    "grantPermissionsDialog.selectedList.permission": "Permission",
    "groupDetails.membersTable.header": "Members",
    "groupDetails.membersTable.name": "Name",
    "groupDetails.membersTable.email": "Email Address",
    "groupDetails.membersTable.removeButtonTitle": "Remove member from group",
    "groupDetails.membersTable.removeButtonTitleDisabled": "You cannot remove unknown members",
    "groupDetails.numberOfMembers": "Number of members in this group",
    "groupDetails.details.hasExternalMembers": "(external members included)",
    "groupDetails.details.memberListHidden": " Member list is hidden, since you are an external user. ",
    "groupList.name": "Group Name",
    "groupList.visibility": "Visibility",
    "groupList.detailsTooltip": "Click to see group details",
    "groupList.visibilityTooltip.company": "Anyone in your company can see this group.",
    "groupList.visibilityTooltip.private": "Only users with appropriate rights can see this group.",
    "groupListTabMenu.manageableGroups": "Management",
    "groupListTabMenu.myGroups": "Membership",
    "groupListTabMenu.visibleGroups": "All Visible",
    "invitation.invalidLink": " The invitation link is invalid or incomplete. Make sure that you clicked the invitation link from the mail or copied it completely. ",
    "invitation.freeTextIntro": " The sender of this invitation has provided additional information: ",
    "invitation.joinIntro": "By accepting this invitation you will",
    "invitation.becomeMember": "become a member of the group",
    "3286758898992408191": "become a manager of the group",
    "invitation.becomeViewer": "be able to view the group",
    "invitation.acceptInvitation": " Accept Invitation ",
    "invitation.declineInvitation": " Decline Invitation ",
    "invitation.sendingMessage": "Sending response...",
    "invitation.alreadyAccepted.title": "Invitation already accepted",
    "invitation.alreadyAccepted.navigateToGroup": " Go to Group Details ",
    "invitation.alreadyDeclined.title": "Invitation already declined",
    "invitation.alreadyDeclined.navigateToHome": " Go to My Groups ",
    "invitation.text": "You have been invited by {$PH} ({$PH_1}) to join the group '{$PH_2}'",
    "invitation.alreadyAccepted.explanation": "You have already accepted the invitation to join the group '{$PH}'",
    "invitation.alreadyDeclined.explanation": "You have already declined this invitation.",
    "searchFieldPlaceholder": "Search Groups",
    "groupListTabMenu.createGroupButton": " Create New Group ",
    "newGroup.title": "New Group",
    "groupPropertiesForm.createGroup": "Create Group",
    "back": "Back",
    "contactSupport.orphanedGroup.description": "Please provide a brief explanation of why you require management access to the group.",
    "contactSupport.orphanedGroup.name": "I require management access to this group.",
    "contactSupport.orphanedGroup.placeholder": "State your explanation here.",
    "contactSupport.feedback.description": "Feel free to share feedback or offer suggestions for new features. We are eager to hear your thoughts and ideas. Thank you for helping us enhance SICK ID Group Management to better meet your needs.",
    "contactSupport.feedback.name": "I want to share suggestions for improvement.",
    "contactSupport.feedback.placeholder": "Enter your feedback here.",
    "contactSupport.otherIssue.description": "Tell us about this issue.",
    "contactSupport.otherIssue.name": "I need assistance with a technical issue.",
    "contactSupport.otherIssue.placeholder": "Include any details we can use to help you.",
    "contactSupport.tooltip": "Contact Support",
    "contactSupport.dialogHeader": "How Can We Help?",
    "contactSupport.introduction": " If you are encountering any challenges or have questions, please specify your request type and provide further details in the text area below. ",
    "contactSupport.result.requestFailed": " Your request could not be sent. Please try again later. ",
    "contactSupport.result.requestSucceeded": " Thank you for your request. We will do our best to assist you as quickly as possible. ",
    "contactSupport.sendingRequest": "Sending your request ...",
    "submit": " Submit ",
    "close": "Close",
    "contactSupport.limitExceeded": "The request text exceeds the maximum allowed length.",
    "footer.imprint": "Imprint",
    "footer.termsAndConditions": "Terms and Conditions",
    "footer.termsOfUse": "Terms of Use",
    "footer.privacyPolicy": "Privacy Policy",
    "navigationBar.applicationName": "Group Management",
    "header.myAccount": "My Account",
    "header.logout": "Logout",
    "notFoundPage.message": "Oops! We couldn't find the page you were looking for.",
    "notFoundPage.goToHomeButton": "Go To My Groups",
    "notFoundPage.goBackButton": "Go Back",
    "error.fallbackErrorMessage": "Oops, something went wrong. We encountered an unknown error.",
    "error.http.notFound": "The server could not find the resource you were looking for.",
    "error.http.conflict": "The server has encountered a conflict. Perhaps an object with the same name already exists?",
    "error.http.forbidden": "You are not allowed to access this resource.",
    "error.http.unknown": "Oops, something went wrong. We had problems communicating with the server.",
    "error.http.invalidParameters": "The server could not handle the last request since it contained invalid parameters.",
    "error.http.maximumLengthExceeded": "The server could not process the last request because one or more inputs exceeded the manageable length.",
    "error.http.unspecifiedBadRequest": "The server could not handle the last request due to invalid syntax.",
    "routes.fallbackTitle": "Group Management"
  }
}
