{
  "locale": "de-DE",
  "translations": {
    "addMembersDialog.addMembers": "Mitglieder Hinzufügen",
    "addMembersDialog.additionalMessage": "Ergänzende Nachricht",
    "addMembersDialog.displayName": "Anzeigename",
    "addMembersDialog.invitationErrorMessage": "Der Einladungstext darf nicht mehr als 2000 Zeichen enthalten.",
    "addMembersDialog.invitationHeader": "Einladung für Personen ohne SICK ID oder außerhalb Ihres Unternehmens:",
    "addMembersDialog.nameErrorMessage": "Der Anzeigename darf nicht mehr als 200 Zeichen enthalten.",
    "addMembersDialog.noUserMessage": "Es sind keine Nutzer ausgewählt.",
    "addMembersDialog.select": "Auswählen",
    "addMembersDialog.selected": "Ausgewählt:",
    "addMembersDialog.selectedList.email": "E-Mail-Adresse",
    "addMembersDialog.selectedList.name": "Name",
    "addMembersDialog.typeToSearchUser": "Suche nach Name oder E-Mail-Adresse",
    "addMembersDialog.willInviteUser": "An den Nutzer wird eine Einladung per E-Mail verschickt",
    "addMembersFeedback.confirm": "Bestätigen",
    "addMembersFeedback.details.result.failure": "Fehlgeschlagen",
    "addMembersFeedback.details.result.success": "Erfolgreich",
    "addMembersFeedback.details.table.alias": "Anzeigename",
    "addMembersFeedback.details.table.email": "Email",
    "addMembersFeedback.details.table.placeholder": "(Kein Anzeigename)",
    "addMembersFeedback.details.table.result": "Einladung",
    "addMembersFeedback.infoAddedMembers": "Es wurde(n) {$PH} Mitglied(er) hinzugefügt.",
    "addMembersFeedback.infoSomeMembersNotAdded": "{$PH} Nutzer war(en) bereits Mitlgied der Gruppe.",
    "addMembersFeedback.invitations": "Einladungen",
    "addMembersFeedback.members": "Mitglieder",
    "addMembersFeedback.sendingInvitations": "Einladungen werden gesendet...",
    "back": "Zurück",
    "contactSupport.dialogHeader": "Wie können wir helfen?",
    "contactSupport.feedback.description": "Teilen Sie uns gerne Ihr Feedback oder Ihre Vorschläge für neue Funktionen mit uns. Wir freuen uns auf Ihre Ideen. Vielen Dank, dass Sie uns dabei unterstützen, SICK ID Group Management zu verbessern.",
    "contactSupport.feedback.name": "Ich möchte Vorschläge zur Verbesserung mitteilen.",
    "contactSupport.feedback.placeholder": "Geben Sie Ihr Feedback hier ein.",
    "close": "Schließen",
    "submit": "Abschicken",
    "contactSupport.introduction": "Wenn Sie auf Probleme stoßen oder Fragen haben, wählen Sie eine der nachfolgenden Optionen und geben Sie weitere Details im Textfeld darunter an.",
    "contactSupport.limitExceeded": "Der Anfragetext überschreitet die maximal zulässige Länge.",
    "contactSupport.otherIssue.description": "Beschreiben Sie Ihr Problem.",
    "contactSupport.otherIssue.name": "Ich benötige Unterstützung bei einem technischen Problem.",
    "contactSupport.otherIssue.placeholder": "Beschreiben Sie Ihr Problem so detailiert wie möglich.",
    "contactSupport.orphanedGroup.description": "Bitte erklären Sie kurz warum Sie Management Zugriff für diese Gruppe benötigen.",
    "contactSupport.orphanedGroup.name": "Ich benötige Management Zugriff für diese Gruppe.",
    "contactSupport.orphanedGroup.placeholder": "Begründen Sie Ihren Antrag hier.",
    "contactSupport.result.requestFailed": "Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es später noch einmal.",
    "contactSupport.result.requestSucceeded": "Vielen Dank für Ihre Anfrage. Wir tun unser Bestes, um Ihnen so schnell wie möglich zu helfen.",
    "contactSupport.sendingRequest": "Anfrage wird gesendet...",
    "contactSupport.tooltip": "Supportanfrage senden",
    "error.fallbackErrorMessage": "Ups, da ist etwas schief gegangen. Ein unbekannter Fehler ist aufgetreten.",
    "error.http.conflict": "Der Server ist auf einen Konflikt gestoßen. Vielleicht existiert bereits ein Objekt mit demselben Namen?",
    "error.http.forbidden": "Sie sind nicht berechtigt, auf diese Ressource zuzugreifen.",
    "error.http.invalidParameters": "Die letzte Anfrage enthielt ungültige Parameter und konnte deshalb vom Server nicht bearbeitet werden.",
    "error.http.maximumLengthExceeded": "Der Server konnte die letzte Anfrage nicht bearbeiten, weil eine oder mehrere Eingaben die maximale Länge überschritten haben.",
    "error.http.notFound": "Der Server konnte die gesuchte Ressource nicht finden.",
    "error.http.unknown": "Ups, da ist etwas schief gelaufen. Wir hatten Probleme bei der Server-Kommunikation.",
    "error.http.unspecifiedBadRequest": "Der Server konnte die letzte Anfrage aufgrund ungültiger Syntax nicht bearbeiten.",
    "grantPermissionsDialog.grantPermissions": "Berechtigungen Erteilen",
    "grantPermissionsDialog.noUserMessage": "Es sind keine Nutzer ausgewählt.",
    "grantPermissionsDialog.selected": "Ausgewählt:",
    "grantPermissionsDialog.selectedList.email": "E-Mail-Adresse",
    "grantPermissionsDialog.selectedList.name": "Name",
    "grantPermissionsDialog.selectedList.permission": "Berechtigung",
    "grantPermissionsDialog.typeToSearchUser": "Suche nach Name oder E-Mail-Adresse",
    "groupDetails.addMembersButton": "Mitglieder Hinzufügen",
    "groupDetails.addPermissionsButton": "Berechtigungen Erteilen",
    "groupDetails.details.customerName": "Kundenname",
    "groupDetails.details.deleteTooltip": "Gruppe Löschen",
    "groupDetails.details.description": "Beschreibung",
    "groupDetails.details.editTooltip": "Gruppendetails Bearbeiten",
    "groupDetails.details.filterPermissions": "Berechtigungen durchsuchen",
    "groupDetails.details.hasExternalMembers": "(inklusive externen Mitgliedern)",
    "groupDetails.details.id": "ID",
    "groupDetails.details.memberListHidden": "Mitglieder sind für externe Nutzer versteckt.",
    "groupDetails.details.searchMembers": "Mitglieder durchsuchen",
    "groupDetails.details.visibility": "Sichtbarkeit",
    "groupDetails.details.visibility-company-info": "Alle Nutzer in Ihrem Unternehmen und Nutzer, die zum Anzeigen und Verwalten berechtigt sind, können diese Gruppe sehen.",
    "groupDetails.details.visibility-private-info": "Diese Gruppe ist nur für ihre Mitglieder, Manager und jeden mit der Berechtigung sichtbar. Andere Benutzer in Ihrem Unternehmen können diese Gruppe NICHT sehen.",
    "groupDetails.externalUser": "Externer Nutzer",
    "groupDetails.grantedPermissionsInfo.granted": "Berechtigungen wurden an {$PH} Nutzer erteilt.",
    "groupDetails.grantedPermissionsInfo.someNotGranted": "Berechtigungen für {$PH} Nutzer waren bereits erteilt.",
    "delete": "Löschen",
    "leaveGroup": "Gruppe Verlassen",
    "groupDetails.invitationsTable.email": "E-Mail Adresse",
    "groupDetails.invitationsTable.invitedAs": "Einladungszweck",
    "groupDetails.invitationsTable.name": "Name",
    "groupDetails.invitationsTable.response.accepted": "Akzeptiert",
    "groupDetails.invitationsTable.response.declined": "Abgelehnt",
    "groupDetails.invitationsTable.response.pending": "Offen",
    "groupDetails.invitationsTable.sentOn": "Verschickt Am",
    "groupDetails.invitationsTable.status": "Status",
    "groupDetails.membersTable.email": "Emailadresse",
    "groupDetails.membersTable.header": "Mitglieder",
    "groupDetails.membersTable.name": "Name",
    "groupDetails.membersTable.removeButtonTitle": "Mitglied aus der Gruppe entfernen",
    "groupDetails.membersTable.removeButtonTitleDisabled": "Unbekannte Mitglieder können nicht entfernt werden",
    "groupDetails.numberOfMembers": "Anzahl der Mitglieder in dieser Gruppe",
    "groupDetails.permissionsTable.disabled.current": "Sie können Ihre eigenen Berechtigungen nicht verändern",
    "groupDetails.permissionsTable.disabled.invisible": "Berechtigungen von unbekannten Nutzern können nicht bearbeitet werden",
    "groupDetails.permissionsTable.dropdown": "Berechtigung ändern",
    "groupDetails.permissionsTable.email": "Emailadresse",
    "groupDetails.permissionsTable.name": "Name",
    "groupDetails.permissionsTable.permission": "Berechtigung",
    "groupDetails.permissionsTable.revokeAllPermissions": "Alle Berechtigungen Entziehen",
    "groupDetails.tabs.invitations": "Einladungen",
    "groupDetails.tabs.members": "Mitglieder",
    "groupDetails.tabs.permissions": "Berechtigungen",
    "cancel": "Abbrechen",
    "groupList.deleteModal.deleteGroupPermanently": "Gruppe Löschen",
    "groupList.deleteModal.header": "Lösche Gruppe {$PH}",
    "groupList.deleteModal.proceedQuestion": "Wollen Sie trotzdem fortfahren?",
    "groupList.deleteModal.warning": "Das Löschen der Gruppe kann nicht rückgängig gemacht werden.",
    "groupList.detailsTooltip": "Klicken um Gruppendetails anzusehen",
    "groupList.name": "Gruppenname",
    "remove": "Entfernen",
    "groupList.visibility": "Sichtbarkeit",
    "groupList.visibilityTooltip.company": "Alle Nutzer in Ihrem Unternehmen können diese Gruppe sehen.",
    "groupList.visibilityTooltip.private": "Nur Nutzer mit entsprechenden Rechten können diese Gruppe sehen.",
    "groupListTabMenu.createGroupButton": "Neue Gruppe Erstellen",
    "groupListTabMenu.manageableGroups": "Verwaltung",
    "groupListTabMenu.myGroups": "Mitgliedschaft",
    "groupListTabMenu.visibleGroups": "Alle Sichtbaren",
    "groupPropertiesForm.createAndGoToGroupDetails": "Gruppe Erstellen & Zu Gruppen Details Navigieren",
    "groupPropertiesForm.createGroup": "Gruppe Erstellen",
    "groupPropertiesForm.customer": "Kundenname",
    "groupPropertiesForm.description": "Beschreibung",
    "groupPropertiesForm.descriptionMaxLengthError": "Die Beschreibung darf nicht länger als {$PH} Zeichen sein.",
    "groupPropertiesForm.editGroupProperties": "Gruppeneigenschaften Bearbeiten",
    "leaveGroup.warning": "Wenn Sie diese Gruppe verlassen, können Sie nur von einem Manager wieder hinzugefügt werden.",
    "groupPropertiesForm.id": "ID",
    "groupPropertiesForm.name": "Gruppenname",
    "groupPropertiesForm.nameMaxLengthError": "Der Name darf nicht länger als {$PH} Zeichen sein.",
    "groupPropertiesForm.nameMinLengthError": "Der Name muss mindestens {$PH} Zeichen lang sein.",
    "groupPropertiesForm.nameNotUniqueError": "Der Name muss innerhalb der Firma / des Kunden eindeutig sein.",
    "groupPropertiesForm.saveChanges": "Änderungen Übernehmen",
    "groupPropertiesForm.visibility": "Sichtbarkeit",
    "header.logout": "Abmelden",
    "header.myAccount": "Mein Konto",
    "invitation.acceptInvitation": "Einladung Akzeptieren",
    "invitation.alreadyAccepted.explanation": "Sie haben die Einladung für die Gruppe '{$PH}' bereits angenommen.",
    "invitation.alreadyAccepted.navigateToGroup": "Zu den Gruppendetails",
    "invitation.alreadyAccepted.title": "Einladung bereits akzeptiert",
    "invitation.alreadyDeclined.explanation": "Sie haben diese Einladung bereits abgelehnt.",
    "invitation.alreadyDeclined.navigateToHome": "Zu Meinen Gruppen",
    "invitation.alreadyDeclined.title": "Einladung bereits abgelehnt",
    "invitation.becomeManager": "werden Sie Manager dieser Gruppe",
    "invitation.becomeMember": "werden Sie Mitglied dieser Gruppe",
    "invitation.becomeViewer": "können Sie diese Gruppe sehen",
    "invitation.declineInvitation": "Einladung Ablehnen",
    "invitation.freeTextIntro": "Der Sender dieser Einladung hat weitere Informationen hinterlegt:",
    "invitation.invalidLink": "Der Einladungslink is ungültig oder unvollständig. Stellen Sie sicher, dass sie den Einladungslink aus der Mail geklickt oder vollständig kopiert haben.",
    "invitation.joinIntro": "Wenn Sie diese Einladung akzeptieren, dann",
    "invitation.sendingMessage": "Antwort wird gesendet...",
    "invitation.text": "Sie wurden von {$PH} ({$PH_1}) eingeladen der Gruppe '{$PH_2}' beizutreten",
    "navigationBar.applicationName": "Gruppenmanagement",
    "newGroup.title": "Neue Gruppe",
    "notFoundPage.goBackButton": "Zurück Gehen",
    "notFoundPage.goToHomeButton": "Zu Meinen Gruppen Gehen",
    "notFoundPage.message": "Ups... Wir können diese Seite nicht finden.",
    "permissionNames.manage-group": "Verwalten",
    "permissionNames.member": "Mitglied",
    "permissionNames.view-group": "Sehen",
    "routes.editGroupProperties": "Gruppenmanagement | Gruppeneigenschaften Bearbeiten",
    "routes.fallbackTitle": "Gruppenmanagement",
    "routes.invitation": "Gruppenmanagement | Einladung",
    "routes.managedGroups": "Gruppenmanagement | Verwaltung",
    "routes.myGroups": "Gruppemanagement | Mitgliedschaft",
    "routes.newGroup": "Gruppenmanagement | Neue Gruppe",
    "routes.visibleGroups": "Gruppenmanagement | Alle Sichtbaren",
    "searchFieldPlaceholder": "Gruppen durchsuchen",
    "userCollection.deselect": "Nutzer aus Liste entfernen",
    "userSearch.timeoutError": "Die Benutzersuche hat zu lange gedauert. Bitte ändern Sie Ihren Suchtext, um es erneut zu versuchen.",
    "footer.imprint": "Impressum",
    "footer.termsAndConditions": "AGB",
    "footer.termsOfUse": "Nutzungsbedingungen",
    "footer.privacyPolicy": "Datenschutzerklärung"
  }
}
