<ng-container>
  @if (viewModel(); as view) {
    <div class="margin-1">
      <app-back-link></app-back-link>
      <app-group-details-properties
        [name]="view.group.display_name"
        [description]="view.group.description"
        [visibility]="view.group.visibility"
        [customerName]="view.group.customer_name"
        [id]="view.group.id"
      ></app-group-details-properties>
      <div>
        <div class="flex-row col-gap-1 justify-start">
          <h2 i18n="@@groupDetails.membersTable.header">Members</h2>
          @if (view.members.totalCount > 0) {
            <app-pill i18n-title="@@groupDetails.numberOfMembers" title="Number of members in this group">
              {{ view.members.totalCount }}
            </app-pill>
          }
          @if (view.hasExternalMembers && view.currentUserIsFromSameCustomer) {
            <p i18n="@@groupDetails.details.hasExternalMembers">(external members included)</p>
          }
        </div>
        @if (view.currentUserIsFromSameCustomer) {
          <div>
            <div class="margin-bottom-1">
              <app-search-field
                id="filterMembers"
                i18n-placeholder="@@groupDetails.details.searchMembers"
                placeholder="Search Members"
                (textChange)="onFilterMembers($event)"
              ></app-search-field>
            </div>
            <app-member-list
              [members]="view.members.entries"
              [rowCount]="view.members.pageLength"
              [totalCount]="view.members.tableCount"
              [loading]="view.members.loading"
              (pageChange)="onPageChange($event)"
            >
            </app-member-list>
          </div>
        } @else {
          <div i18n="@@groupDetails.details.memberListHidden">
            Member list is hidden, since you are an external user.
          </div>
        }
      </div>
    </div>
  }
</ng-container>
