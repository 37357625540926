import { Component } from '@angular/core';

@Component({
  selector: 'app-pill',
  template: ` <div class="pill-container"><ng-content></ng-content></div> `,
  styles: [
    `
      :host {
        margin-inline-start: 8px;
      }
      .pill-container {
        border-radius: 100rem;
        background: var(--syn-color-neutral-200);
        color: var(--color-text);
        display: inline-block;
        min-width: calc(1em * var(--default-line-height));
        padding-inline: 0.4em;
        padding-block: 0.1em;
        text-align: center;
        font-weight: bold;
      }

      .pill-container:empty::after {
        content: '-'; /* This is necessary to avoid jumping during the animation due height changes */
      }

      @keyframes expand {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    `,
  ],
})
export class PillComponent {}
