<syn-header i18n-label="@@navigationBar.applicationName" label="Group Management">
  <nav slot="meta-navigation">
    @if (state.supportView.canActivate()) {
      <syn-select
        size="small"
        style="width: 7em"
        [attr.value]="state.banner.type()"
        (syn-change)="onBannerTypeChange($event)"
      >
        <syn-option value="none">None</syn-option>
        <syn-option value="info">
          <syn-icon name="info" slot="prefix"></syn-icon>
          Info
        </syn-option>
        <syn-option value="warning">
          <syn-icon name="warning" slot="prefix"></syn-icon>
          Warning
        </syn-option>
        <syn-option value="error">
          <syn-icon name="error" slot="prefix"></syn-icon>
          Error
        </syn-option>
      </syn-select>
      <syn-input
        placeholder="Banner text"
        [attr.value]="state.banner.text()"
        size="small"
        (syn-change)="onBannerTextChange($event)"
      >
      </syn-input>
      <div class="flex-row" style="font-size: small">
        <span class="label">Support View:</span>
        <app-toggle-switch [checked]="state.supportView.active()" (change)="onSupportViewChange($event)">
        </app-toggle-switch>
      </div>
    }

    <app-contact-support></app-contact-support>

    <!-- TODO: Actually use iconLanguage and iconPerson again -->
    <syn-dropdown placement="bottom-start">
      <syn-icon-button
        id="language"
        name="language"
        label="Language"
        color="neutral"
        slot="trigger"
        data-e2e="header-language"
      >
      </syn-icon-button>

      <syn-menu id="languageMenu" (syn-select)="onLanguageSelection($event)" #languageMenu>
        @for (locale of availableLocales; track locale.id) {
          <syn-menu-item
            [attr.value]="locale.id"
            [attr.data-e2e]="'header-language-' + locale.id"
            type="checkbox"
            [attr.checked]="isCurrentLanguage(locale.id)"
          >
            {{ locale.label }}
          </syn-menu-item>
        }
      </syn-menu>
    </syn-dropdown>

    <syn-dropdown placement="bottom-start">
      <syn-icon-button
        id="header-user"
        name="account_circle"
        label="Account"
        color="neutral"
        slot="trigger"
        data-e2e="header-user"
      ></syn-icon-button>

      <syn-menu id="accountMenu">
        <div class="vertical menu">
          @if (isAuthenticated()) {
            <div class="horizontal">
              <syn-icon class="account-icon" name="account_box"></syn-icon>
              <div class="vertical">
                <div>{{ userName() }}</div>
                <div class="email">{{ userEmail() }}</div>
              </div>
            </div>
            <a [attr.href]="myAccountLink">
              <app-button i18n="@@header.myAccount">My Account</app-button>
            </a>
            <app-button type="outline" (submit)="onLogout()" i18n="@@header.logout">Logout</app-button>
          }
        </div>
      </syn-menu>
    </syn-dropdown>
  </nav>
</syn-header>
