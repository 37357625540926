import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { invitationActions, selectInvitation } from '../store/groups.feature';
import { InvitationResponse, SenderInfoDto } from '../../api/dtos';
import { GroupUser } from '../model';
import { filter } from 'rxjs';
import { InvitationPageParams } from '../../api/group';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../shared/components/spinner.component';
import { ButtonComponent } from '../../shared/components/button.component';
import { InlineNotificationComponent } from '../../shared/components/inline-notification';
import { Title } from '@angular/platform-browser';

export interface InvitationViewModel {
  groupName: string;
  groupId: string;
  freeText: string;
  isMember: boolean;
  isManager: boolean;
  isViewer: boolean;

  sender: GroupUser;
  response: InvitationResponse;

  sendingResponse: boolean;
}

export function senderInfoToUser(info?: SenderInfoDto): GroupUser {
  if (!info) {
    return { id: '', name: '', isVisible: false };
  }
  return {
    id: '',
    name: `${info.first_name} ${info.last_name}`,
    email: info.email ?? undefined,
    isVisible: true,
  };
}

const selectViewModel = createSelector(selectInvitation, (invitation): InvitationViewModel | undefined => {
  if (!invitation.data) {
    return;
  }

  const view: InvitationViewModel = {
    groupName: invitation.data.group_display_name,
    groupId: invitation.data.group_id,
    freeText: invitation.data.free_text,
    isMember: invitation.data.types.includes('member'),
    isManager: invitation.data.types.includes('manage-group'),
    isViewer: invitation.data.types.includes('view-group'),
    sender: senderInfoToUser(invitation.data.sender),
    response: invitation.data.response,
    sendingResponse: invitation.sendingResponse,
  };

  return view;
});

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ButtonComponent, SpinnerComponent, RouterLink, AsyncPipe, InlineNotificationComponent],
})
export class InvitationComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute).snapshot;
  private title = inject(Title);

  private groupId = this.route.queryParamMap.get('group') || '';
  code: string = this.route.queryParamMap.get('id') || '';
  invalidLink = this.groupId.length === 0 || this.code.length === 0;

  viewModel$ = this.store.select(selectViewModel).pipe(filter((viewModel) => viewModel !== undefined));

  private params(): InvitationPageParams {
    let link = window.location.href;
    // The link is checked using a hash and the backend uses the official frontend URLs
    // For testing purposes, we replace http://localhost:4200 with the frontend URL
    link = link.replace('http://localhost:4200', environment.frontendUrl);
    link = link.replace('http://127.0.0.1:4200', environment.frontendUrl);

    return {
      groupId: this.groupId,
      invitationCode: this.code,
      link,
    };
  }

  ngOnInit(): void {
    this.store.dispatch(invitationActions.requested(this.params()));
    this.title.setTitle($localize`:@@routes.invitation:Group Management | Invitation`);
  }

  onAccept() {
    this.store.dispatch(invitationActions.responseChosen(this.params(), 'accept'));
  }

  onDecline() {
    this.store.dispatch(invitationActions.responseChosen(this.params(), 'decline'));
  }

  getText(groupName: string, sender: GroupUser): string {
    return $localize`:@@invitation.text:You have been invited by ${sender.name} (${sender.email}) to join the group '${groupName}'`;
  }

  getAlreadyAcceptedExplanation(groupName: string) {
    return $localize`:@@invitation.alreadyAccepted.explanation:You have already accepted the invitation to join the group '${groupName}'`;
  }

  getAlreadyDeclinedExplanation(_: string) {
    return $localize`:@@invitation.alreadyDeclined.explanation:You have already declined this invitation.`;
  }
}
