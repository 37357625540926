import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { StateService } from '../store/state';

@Component({
  selector: 'app-banner',
  template: `
    <syn-alert [attr.variant]="variant()" [attr.open]="isActive()">
      <syn-icon slot="icon" [attr.name]="icon()"></syn-icon>
      {{ text() }}
    </syn-alert>
  `,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
})
export class BannerComponent {
  state = inject(StateService);

  isActive = computed(() => (this.state.banner.type() !== 'none' ? true : null));

  variant = computed(() => {
    switch (this.state.banner.type()) {
      case 'info':
        return 'primary';
      case 'warning':
        return 'warning';
      case 'error':
        return 'danger';
      default:
        return 'neutral';
    }
  });

  icon = computed(() => {
    const type = this.state.banner.type();
    switch (type) {
      case 'info':
      case 'warning':
      case 'error':
        return type;
      default:
        return 'info';
    }
  });

  text = computed(() => this.state.banner.text());
}
