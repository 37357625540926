import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageChangeEvent, GroupListComponent } from '../group-list/group-list.component';
import { GroupListService } from '../group-list/group-list.service';
import { AsyncPipe } from '@angular/common';
import { SearchFieldComponent } from '../../shared/components/search-field/search-field.component';
import { ButtonComponent } from '../../shared/components/button.component';
import { RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-visible-groups',
  template: `@if (viewModel$ | async; as viewModel) {
    <div class="margin-1">
      <div class="margin-block-1 flex-row col-gap-1">
        <app-search-field
          [formControl]="searchText"
          i18n-placeholder="@@searchFieldPlaceholder"
          placeholder="Search Groups"
          data-testid="search-visible-groups"
          style="width: 100%;"
        ></app-search-field>
        <app-button
          class="create-button"
          data-testid="create-group-visible"
          icon="plus"
          routerLink="/new"
          i18n="@@groupListTabMenu.createGroupButton"
        >
          Create New Group
        </app-button>
      </div>
      <app-group-list
        [groups]="viewModel.groups"
        [rowCount]="viewModel.pageLength"
        [totalCount]="viewModel.totalCounts.visible"
        [loading]="!viewModel.pageLoaded"
        [baseLink]="'visible-groups'"
        (onPageChange)="onPageChange($event)"
      ></app-group-list>
    </div>
  }`,
  styles: [],
  imports: [
    SearchFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    GroupListComponent,
    ButtonComponent,
    RouterLink,
    AsyncPipe,
  ],
})
export class VisibleGroupsComponent implements OnInit, OnDestroy {
  private groupListService = inject(GroupListService);
  private title = inject(Title);
  readonly viewModel$ = this.groupListService.viewModel$;
  readonly searchText = new FormControl('');

  ngOnInit(): void {
    this.groupListService.subscribe(this.searchText.valueChanges);
  }

  ngOnDestroy(): void {
    this.groupListService.unsubscribe();
  }

  open(): void {
    this.groupListService.open('visible');
    this.title.setTitle($localize`:@@routes.visibleGroups:Group Management | All Visible`);
  }

  onPageChange(event: PageChangeEvent) {
    this.groupListService.onPageChange(event);
  }
}
