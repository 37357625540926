import { Component, computed, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GroupPropertiesSubmitEvent } from '../model';
import { groupCreationActions } from '../store/groups.feature';
import { GroupPropertiesFormComponent } from '../components/group-properties-form/group-properties-form.component';
import { BackLinkComponent } from '../../shared/components/back-link.component';
import { StateService } from '../../shared/store/state';
import { AuthUser } from '../../auth/types';
import { Title } from '@angular/platform-browser';

export function computeCustomerName(user: AuthUser | null) {
  const customerName = user?.customerName;
  if (!customerName && user?.email?.endsWith('@sick.de')) {
    return 'SICK AG';
  }
  return customerName;
}

@Component({
  selector: 'app-new-group',
  template: `
    <div class="margin-1">
      <app-back-link></app-back-link>
      <h2 i18n="@@newGroup.title">New Group</h2>
      <app-group-properties-form
        [customerName]="customerName()"
        [submitButton]="createGroup"
        [cancelButton]="true"
        (submit)="onSubmit($event)"
      >
      </app-group-properties-form>
    </div>
  `,
  styles: [],
  imports: [BackLinkComponent, GroupPropertiesFormComponent],
})
export class NewGroupComponent implements OnInit {
  private store = inject(Store);
  private state = inject(StateService);
  private title = inject(Title);

  createGroup = $localize`:@@groupPropertiesForm.createGroup:Create Group`;

  customerName = computed(() => computeCustomerName(this.state.authUser()));

  ngOnInit(): void {
    this.title.setTitle($localize`:@@routes.newGroup:Group Management | New Group`);
  }

  onSubmit(event: GroupPropertiesSubmitEvent) {
    this.store.dispatch(groupCreationActions.groupCreationRequested(event));
  }
}
