import { APP_INITIALIZER } from '@angular/core';
import { initAuthentication } from './auth/auth';
import { StateService } from './shared/store/state';

export const authInitializer = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (state: StateService) => () => initAuthentication(state),
  deps: [StateService],
};

export const appInitializers = [authInitializer];
