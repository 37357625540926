import { Injectable, signal, WritableSignal } from '@angular/core';
import { BannerType, ConfigurationDto } from 'src/app/api/dtos';
import { AuthUser } from 'src/app/auth/types';

export type ErrorType = 'ApiError' | 'UnknownError';

export interface ApplicationError {
  type: ErrorType;
  message: string;
}

export const UNKNOWN_ERROR: ApplicationError = {
  type: 'UnknownError',
  message: $localize`:@@error.fallbackErrorMessage:Oops, something went wrong. We encountered an unknown error.`,
};

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public banner = {
    type: signal<BannerType>('none'),
    text: signal(''),
  };

  public supportView = {
    canActivate: signal(false),
    active: signal(false),
  };

  public error: WritableSignal<ApplicationError | null> = signal(null);

  public authUser: WritableSignal<AuthUser | null> = signal(null);

  public exception = (error: Error) => {
    console.error(error);
    this.error.set(UNKNOWN_ERROR);
  };

  public getConfiguration(): ConfigurationDto {
    return {
      banner: {
        type: this.banner.type(),
        text: this.banner.text(),
      },
    };
  }
}
