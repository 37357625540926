import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, ViewChild, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { groupListActions } from '../../../groups/store/groups.feature';
import { ContactSupportComponent } from '../contact-support/contact-support.component';
import { SUPPORTED_LANGUAGES, I18n } from '../../../i18n';
import { ToggleSwitchComponent } from '../toggle-switch.component';
import { ButtonComponent } from '../button.component';
import { StateService } from '../../store/state';
import { BannerType } from 'src/app/api/dtos';
import { GroupHttpApi } from 'src/app/api/http';
import { keycloak } from 'src/app/auth/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    `
      .vertical {
        display: flex;
        flex-direction: column;
        margin: auto;
      }
      .horizontal {
        display: flex;
        flex-direction: row;
      }
      .menu {
        padding: 0.8em;
        row-gap: 0.8em;
      }
      .header-border {
        border-bottom: 3px solid #bac2c6;
      }
      .email {
        padding-top: 0.2em;
        font-size: smaller;
      }
      .account-icon {
        width: 36px;
        height: 36px;
      }
      .label {
        padding-right: 0.5rem;
      }

      app-button {
        width: 100%;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [ContactSupportComponent, ToggleSwitchComponent, ButtonComponent],
})
export class HeaderComponent {
  private store = inject(Store);
  state = inject(StateService);
  api = inject(GroupHttpApi);
  private i18n = inject(I18n);

  myAccountLink = environment.auth.url + `/realms/${environment.auth.realm}/account`;
  availableLocales = SUPPORTED_LANGUAGES;

  @ViewChild('languageMenu') languageMenu: ElementRef | undefined;

  isAuthenticated = computed(() => this.state.authUser() !== null);
  userName = computed(() => this.state.authUser()?.userName);
  userEmail = computed(() => this.state.authUser()?.email);

  onBannerTypeChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.state.banner.type.set(target.value as BannerType);

    this.updateConfiguration();
  }

  onBannerTextChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.state.banner.text.set(target.value);

    this.updateConfiguration();
  }

  updateConfiguration() {
    const config = this.state.getConfiguration();
    this.api.setConfiguration(config).subscribe((newConfig) => {
      console.log('Updated configuration', newConfig);
    });
  }

  onLanguageSelection(event: Event) {
    if (event.target === this.languageMenu?.nativeElement) {
      const languageId = (event as CustomEvent).detail.item.value;
      this.i18n.setLanguage(languageId);
      // Reload the page after we changed the language
      location.reload();
    }
  }

  isCurrentLanguage(id: string): boolean | null {
    return this.i18n.locale === id ? true : null;
  }

  onSupportViewChange(checked: boolean) {
    this.state.supportView.active.set(checked);
    // Need to reload the group list if we switch to support view
    this.store.dispatch(groupListActions.initialized());
  }

  onLogout() {
    keycloak
      .logout()
      .then(() => console.log('Logged out'))
      .catch(this.state.exception);
  }
}
