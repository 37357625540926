import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { environment } from '../../environments/environment';
import { AuthUser } from './types';
import { StateService } from '../shared/store/state';

// We store the access token and user UUID in the window object
// to make them accessible in the end-to-end tests
declare global {
  interface Window {
    accessToken?: string;
    userId?: string;
  }
}

export const keycloak = new Keycloak({
  url: environment.auth.url,
  realm: environment.auth.realm,
  clientId: environment.auth.clientId,
});

export let authUser: AuthUser | null = null;

export async function initAuthentication(state: StateService): Promise<void> {
  // TODO: Handle timeout error, when Keycloak is not available!
  await keycloak.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    scope: 'openid profile email',
  });

  if (keycloak.token === undefined) {
    await keycloak.login();
  }

  window.accessToken = keycloak.token;

  if (keycloak.tokenParsed) {
    authUser = tokenToAuthUser(keycloak.tokenParsed);
    state.authUser.set(authUser);

    window.userId = authUser.id;
  }
}

export async function getAccessToken(): Promise<string> {
  try {
    await keycloak.updateToken(5);
  } catch (_error) {
    await keycloak.login();
  }

  if (!keycloak.token) {
    throw new Error('Failed to authenticate user');
  }

  window.accessToken = keycloak.token;

  return keycloak.token;
}

export interface AuthUserDataDto extends KeycloakTokenParsed {
  name?: string;
  given_name?: string;
  family_name?: string;
  preferred_username?: string;
  y_CustomerName?: string;
  y_CustomerNumber?: string;
  locale?: string;
  email?: string;
  email_verified?: boolean;
}

function tokenToAuthUser(token: AuthUserDataDto): AuthUser {
  return {
    id: token.sub ?? '',
    firstName: token.given_name ?? '',
    lastName: token.family_name ?? '',
    userName: token.preferred_username ?? '',
    fullName: token.name ?? '',
    isEmailVerified: token.email_verified ?? false,
    email: token.email,
    locale: token.locale,
    customerName: token.y_CustomerName,
    customerNumber: token.y_CustomerNumber,
  };
}
