import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { PageChangeEvent } from '../../group-list/group-list.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { GroupUser } from '../../model';
import { TableDirective } from '../../../shared/components/table/table.directive';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styles: [
    `
      .external-user {
        font-style: italic;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [TableComponent, TableDirective],
})
export class MemberListComponent {
  @Input() members: GroupUser[] = [];
  @Input() showMemberDetails = false;
  @Input() showRemoveButton = false;
  @Input() rowCount = 10;
  @Input() totalCount = 0;
  @Input() loading = false;
  @Output() removeMember = new EventEmitter<GroupUser>();
  @Output() pageChange = new EventEmitter<PageChangeEvent>();
  @ViewChild(TableComponent) table!: TableComponent<unknown>;

  onRemoveMember(member: GroupUser) {
    this.removeMember.emit(member);
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (event.first !== undefined) {
      this.pageChange.emit({ offset: event.first });
    }
  }

  isMemberEditable(member: GroupUser): boolean {
    return member.isVisible;
  }

  getRemoveButtonTitle(member: GroupUser): string {
    if (member.isVisible) {
      return $localize`:@@groupDetails.membersTable.removeButtonTitle:Remove member from group`;
    } else {
      return $localize`:@@groupDetails.membersTable.removeButtonTitleDisabled:You cannot remove unknown members`;
    }
  }
}
