import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { selectGroupDetails } from '../store/groups.feature';
import { InvitationAndResultDto, SendInvitationResult } from '../../api/dtos';
import { AddedMembersInfo, editGroupActions } from '../store/group-details/group-details.slice';
import { ButtonComponent } from '../../shared/components/button.component';
import { SpinnerComponent } from '../../shared/components/spinner.component';
import { TableDirective } from '../../shared/components/table/table.directive';
import { TableComponent } from '../../shared/components/table/table.component';
import { NgStyle, AsyncPipe } from '@angular/common';
import { InlineNotificationComponent } from '../../shared/components/inline-notification';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';

interface InvitedUsersInfo {
  summary: SendInvitationResult;
  totalCount: number;
  failureCount: number;
  details: InvitationAndResultDto[];
}

interface ViewModel {
  invitedUsers: InvitedUsersInfo | undefined;
  addedMembers: AddedMembersInfo | undefined;
}

@Component({
  selector: 'app-add-members-feedback',
  template: `
    <app-dialog (onHide)="onHide()" i18n-header="@@addMembersFeedback.members" header="Members" width="60vw">
      <!-- TODO: Choose the title better! -->
      <!-- If you just invite users, you see two titles: Members and Invitations -->
      @if (model | async; as model) {
        <div style="height: 60vh">
          @if (model.addedMembers && (model.addedMembers.added > 0 || model.addedMembers.alreadyMember > 0)) {
            <div class="notification">
              <app-inline-notification [variant]="'success'" [animated]="false" [closeable]="false">
                {{ translateAddedMembers(model.addedMembers) }}
              </app-inline-notification>
            </div>
          }
          @if (hasInvitedUsers) {
            @if (model.addedMembers) {
              <h2 i18n="@@addMembersFeedback.invitations">Invitations</h2>
            }
            @if (model.invitedUsers) {
              <div [ngStyle]="{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }">
                <section id="details">
                  <app-table [values]="model.invitedUsers.details" [showPagination]="false" scrollHeight="16rem">
                    <ng-template tableTemplate="header">
                      <tr>
                        <th [ngStyle]="{ width: '40%' }" i18n="@@addMembersFeedback.details.table.alias">
                          Display Name
                        </th>
                        <th [ngStyle]="{ width: '40%' }" i18n="@@addMembersFeedback.details.table.email">Email</th>
                        <th [ngStyle]="{ width: '20%' }" i18n="@@addMembersFeedback.details.table.result">
                          Invitation
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template tableTemplate="body" let-detail>
                      <tr>
                        <td>
                          @if (detail.invitation.display_name !== '') {
                            <span>{{ detail.invitation.display_name }}</span>
                          } @else {
                            <span class="no-alias-placeholder" i18n="@@addMembersFeedback.details.table.placeholder"
                              >(No Display Name)</span
                            >
                          }
                        </td>
                        <td>{{ detail.invitation.email }}</td>
                        <td>
                          <span>
                            <syn-icon
                              [attr.name]="detail.result === 'success' ? 'check' : 'error'"
                              [style.color]="
                                detail.result === 'success'
                                  ? 'var(--syn-color-success-600)'
                                  : 'var(--syn-color-error-600)'
                              "
                            >
                            </syn-icon>
                            {{ translateInvitationResult(detail.result) }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </app-table>
                </section>
              </div>
            } @else {
              <app-spinner
                i18n-label="@@addMembersFeedback.sendingInvitations"
                label="Sending Invitations..."
              ></app-spinner>
            }
          }
        </div>
        <app-button
          slot="footer"
          id="add-member-confirm"
          (submit)="closeDialog()"
          [disabled]="hasInvitedUsers && !model.invitedUsers"
          i18n="@@addMembersFeedback.confirm"
        >
          Confirm
        </app-button>
      }
    </app-dialog>
  `,
  styles: [
    `
      section#details .no-alias-placeholder {
        color: var(--syn-color-neutral-700);
        font-style: italic;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    DialogComponent,
    InlineNotificationComponent,
    NgStyle,
    TableComponent,
    TableDirective,
    SpinnerComponent,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class AddMembersFeedbackComponent {
  @Input() hasInvitedUsers = false;
  @Output() close = new EventEmitter<void>();

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  private store = inject(Store);
  readonly model = this.store.select(selectInvitationResults);

  closeDialog() {
    this.close.emit();
    this.dialog.hide();
  }

  onHide() {
    this.store.dispatch(editGroupActions.clearAddedMembers());
  }

  translateInvitationResult(result: SendInvitationResult): string {
    if (result === 'success') {
      return $localize`:@@addMembersFeedback.details.result.success:Succeeded`;
    } else {
      return $localize`:@@addMembersFeedback.details.result.failure:Failed`;
    }
  }

  translateAddedMembers(info: AddedMembersInfo): string {
    if (info.added > 0) {
      return $localize`:@@addMembersFeedback.infoAddedMembers:Added ${info.added} member(s).`;
    }
    if (info.alreadyMember > 0) {
      return $localize`:@@addMembersFeedback.infoSomeMembersNotAdded:Did not add ${info.alreadyMember} membe(s).`;
    }
    return '';
  }
}

const selectInvitationResults = createSelector(selectGroupDetails, (groupDetails): ViewModel | undefined => {
  if (!groupDetails) {
    return;
  }

  let invitedUsers: InvitedUsersInfo | undefined = undefined;
  if (groupDetails.sentInvitations) {
    const totalCount = groupDetails.sentInvitations.results.length;
    const failureCount = groupDetails.sentInvitations.results.filter(
      (invitationData) => invitationData.result === 'failure'
    ).length;
    const summary: SendInvitationResult = failureCount === 0 ? 'success' : 'failure';
    invitedUsers = {
      summary,
      totalCount,
      failureCount,
      details: groupDetails.sentInvitations.results,
    };
  }

  return {
    addedMembers: groupDetails.addedMembers,
    invitedUsers,
  };
});
